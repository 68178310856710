import {Component} from '@angular/core';
import {DialogService} from '../../services/dialog.service';

@Component({
    templateUrl: './how-it-works-dialog.html',
    styleUrl: './how-it-works-dialog.scss'
})
export class HowItWorksDialog {
    constructor(
        private readonly dialogService: DialogService
    ) {
    }
    public closeDialog(): void {
        this.dialogService.closeHowItWorksDialog();
    }
}
