<div [ngClass]="productBigScreen ? 'flex-row' : 'flex-column'"
     class="order-card-dialog h-100 d-flex justify-content-between m-0 p-0">

    <div [ngClass]="productBigScreen ? 'product-img' : 'partner-img'" class="bg-white">

        <div class="position-relative h-100">
            <img [ngSrc]="getImageSrc()" [placeholder]="placeholderImgBase64" alt="product-img" class="object-fit-cover" [fill]="true" [priority]="true"/>
        </div>

        <!--   Off During Sales    -->
        @if (marketplaceService.isSalesPeriod && partnerIsOffDuringSales) {
            <div class="order-card-sales w-100 h-100 text-large text-center color-white position-absolute top-0 end-0">
                <div class="d-flex flex-row g-0">
                    <p class="col text-center pt-5">Indisponible durant les soldes</p>
                    @if (!productBigScreen) {
                        <div class="text-end pe-3 pt-2">
                            <i (click)="dialogService.closeOrderCardDialog()" aria-hidden="true"
                               class="fas fa-times fa-3x clickable"></i>
                        </div>
                    }
                </div>
            </div>
        }
        @if (productBigScreen && !marketplaceService.isSalesPeriod || (marketplaceService.isSalesPeriod && !partnerIsOffDuringSales)) {
            <div (click)="goToEShop()"
                 id="hoverProduct"
                 class="d-none h-100 w-100 color-white flex-column justify-content-center text-center clickable position-absolute top-0 end-0">
                @if (product?.url) {
                    <a [href]="product?.url"
                       target="_blank"
                       class="text-small font-weight-light text-decoration-underline justify-content-center">Voir le produit sur le site
                    </a>
                }
            </div>
        }
        @if (!marketplaceService.isSalesPeriod || !partnerIsOffDuringSales) {
            <div class="row m-0 position-absolute top-0 end-0">
                @if (!productBigScreen && selectedOffer && !orderedVoucher) {
                    <div class="col text-start ms-2 mt-2">
                        <i (click)="selectedOffer = undefined" aria-hidden="true"
                           class="fas fa-arrow-left fa-3x clickable"></i>
                    </div>
                }
                @if (!productBigScreen) {
                    <div class="col me-2 mt-2 text-end">
                        <i (click)="dialogService.closeOrderCardDialog()" aria-hidden="true"
                           class="fas fa-times fa-3x clickable"></i>
                    </div>
                }
            </div>
        }
    </div>

    <div [ngClass]="productBigScreen ? 'text-start' : 'text-center'"
         class="order-card-content bg-black w-100 d-flex flex-column flex-fill m-0 pb-3 pb-lg-2 color-white" style="z-index: 1">
        @if (productBigScreen) {
            <div class="row g-0">
                <div class="col me-3 mt-2 text-end">
                    <i (click)="dialogService.closeOrderCardDialog()" aria-hidden="true"
                       class="fas fa-times fa-3x clickable"></i>
                </div>
            </div>
            <div class="mt-3 mb-3 d-flex">
                <div>
                    @if (partner?.resourcesUuid) {
                        <img (error)="uiService.handleMissingImage($event)"
                             [ngSrc]="env.cdnUrl + '/partners/resources/' + partner?.resourcesUuid + '/logo_200x200.png'"
                             [placeholder]="placeholderImgBase64"
                             priority
                             alt="{{partner?.name}}"
                             class="partner-logo-product bg-white circle mat-elevation-z2"
                             height="110"
                             width="110"/>
                    }
                </div>
                <div class="">
                    @if (!selectedOffer && !orderedVoucher) {
                        <p class="text-larger bold">
                            {{ product?.label }}
                        </p>
                    }
                    <span class="text-normal me-3">{{ partner?.name }}</span>
                    @if (product?.url) {
                        <a [href]="product?.url"
                           target="_blank"
                           class="text-small font-weight-light text-decoration-underline">Voir le produit
                        </a>
                    }
                    @if (productPartnerOffer) {
                        <div class="mt-4 mb-2">
                            <span>
                                Prix : <span class="bold">{{ product?.price | currency : 'EUR' }}</span>
                            </span>
                            <span class="mx-4">
                                Carte cadeau : <span class="bold">{{ productPartnerOffer.price | currency : 'EUR' }}</span>
                            </span>
                            <span>
                                Complément CB : <span class="bold">{{ calculateProductComplement() | currency : 'EUR' : 'symbol':'1.2-2'}}</span>
                            </span>
                        </div>
                    }
                </div>
            </div>
        }
        @if (!productBigScreen) {
            <div class="mb-3">
                @if (partner?.resourcesUuid) {
                    <img (error)="uiService.handleMissingImage($event)"
                         [ngSrc]="env.cdnUrl + '/partners/resources/' + partner?.resourcesUuid + '/logo_200x200.png'"
                         [placeholder]="placeholderImgBase64" priority
                         alt="{{partner?.name}}" class="partner-logo bg-white circle mx-auto" height="100" width="100"/>
                }
                <p class="mt-3 mb-0 text-larger bold">{{ partner?.name }}</p>
                @if (product?.url) {
                    <p class="my-3">
                        <a [href]="product?.url" target="_blank" class="text-decoration-underline">Voir le produit</a>
                    </p>
                }
                @if (!product && partner?.url && partner?.url !== '/') {
                    <p class="my-3">
                        <a [href]="partner?.url"
                           (click)="trackingService.trackEvent('partner Website', 'partner Website ' + partner?.name, partner?.name, partner?.id)"
                           target="_blank" class="text-decoration-underline">Aller sur le site</a>
                    </p>
                }
                @if (!selectedOffer && !orderedVoucher && product) {
                    <p class="m-0 text-normal text-center">
                        {{ product.label }}
                    </p>
                }
            </div>
        }

        @if (!selectedOffer && !orderedVoucher) {
            <div class="flex-fill">
                <div class="row m-0 text-left">
                    <p [innerHTML]="partner?.description" class="col-10 mx-auto mb-0"></p>
                </div>
            </div>

            <!--   Select offer     -->
            <div>
                @if (product && smallScreen && productPartnerOffer) {
                    <div class="row m-0 justify-content-center">
                        <div class="mx-2 col">
                            <app-price-box [price]="product.price || 0" label="Prix du produit" />
                        </div>
                        <div class="mx-2 col">
                            <app-price-box [price]="productPartnerOffer.price || 0" label="Valeur du code" />
                        </div>
                        <div class="mx-2 col">
                            <app-price-box [price]="calculateProductComplement()" label="Complément" />
                        </div>
                    </div>
                }

                <div [ngClass]="productBigScreen ? 'justify-content-end mt-2' : 'justify-content-center mt-2'"
                     class="d-flex flex-wrap mb-2 m-0 px-md-4 px-lg-5">
                    @for (partnerOffer of partnerOffers; track partnerOffer.id) {
                        <div class="p-2">
                            <button [disabled]="marketplaceService.isSalesPeriod && partnerIsOffDuringSales"
                                    (click)="selectOfferToOrder(partnerOffer)"
                                    class="btn-validate px-5 py-4 text-normal bold"
                                    mat-raised-button>
                                Commander un code de {{ partnerOffer.price }} &euro;{{ partnerOffer.type === 1 ? '*' : '' }}
                            </button>
                            @if (partnerOffer.type === 1) {
                                <p [class.text-end]="productBigScreen" class="mt-2 mb-0">
                                    {{ '* Minimum d\'achat de ' + partnerOffer.price * 2 + ' €'}}
                                </p>
                            }
                        </div>
                    }
                </div>
                @if (!partnerOffers.length) {
                    <div class="m-lg-2 px-lg-5 py-1 text-normal color-red bold">
                        Votre solde est insuffisant pour commander chez ce partenaire.
                    </div>
                }
            </div>
        } @else if (selectedOffer && !orderedVoucher) {
            <!--    Validate      -->
            <div class="flex-fill d-flex flex-column">
                <div class="row g-0 text-normal flex-fill">
                    <div class="col-10 mx-auto">
                        <div class="pb-3">
                            <i class="fas fa-info-circle me-2" aria-hidden="true"></i>
                            Vous pouvez bénéficier d'une seule carte cadeau par enseigne.
                            Une fois votre commande passée, vous ne pourrez plus l'annuler
                        </div>
                        <div class="text-left">
                            @if (restrictions.length) {
                                <ul>
                                    @for (restriction of restrictions; track restriction) {
                                        <li>{{ restriction }}</li>
                                    }
                                </ul>
                            }
                            <ul>
                                @if (selectedOffer.type === 1) {
                                    <li>
                                        Minimum d'achat de {{selectedOffer.price * 2}} &euro;
                                    </li>
                                }
                                @if (partnerIsOffDuringSales) {
                                    <li>
                                        Non valable pendant les soldes et autres promotions
                                    </li>
                                }
                                <li class="clickable text-decoration-underline" routerLink="/terms-and-conditions">
                                    Conditions générales
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="row g-0 text-small">
                    <div class="col-10 mx-auto">
                        <input type="checkbox" id="confirmed" [(ngModel)]="confirmed" [disabled]="isOrdering">
                        <label for="confirmed" class="ps-3">J'accepte les conditions générales</label>
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto p-2">
                    @if (isOrdering) {
                        <mat-spinner  [diameter]="32" [strokeWidth]="4" class="mx-auto my-3" />
                    } @else {
                        <button (click)="orderSelectedOffer()"
                                [disabled]="!confirmed || !consumersService.getConsumer().id"
                                class="btn-validate mt-3 px-5 py-4 bold"
                                mat-raised-button>
                            Commander un code de {{ selectedOffer.price }} &euro;
                        </button>
                    }
                </div>
            </div>
        } @else if (orderedVoucher) {
            <!--    Display voucher    -->
            <div class="text-center">
                <div class="row g-0">
                    <div class="pb-3 text-normal">
                        Félicitations, voici votre code
                    </div>
                    <div (click)="uiService.copyToClipboard(orderedVoucher.code, $event.target)"
                         class="voucher-box bg-white-60 cliquable mx-auto text-title bold">
                        {{ orderedVoucher.code }}
                    </div>
                    <div class="py-2 text-small">
                        <a (click)="uiService.copyToClipboard(orderedVoucher.code, $event.target)" class="clickable">
                            Cliquez pour copier le code
                        </a>
                    </div>
                    @if (orderedVoucher.secret) {
                        <!--    Display secret     -->
                        <div class="pb-3 text-normal">PIN :</div>
                        <div class="voucher-box bg-white-60 cliquable mx-auto text-title bold">
                            {{ orderedVoucher.secret }}
                        </div>
                    }
                </div>
                <div class="row m-0 mt-5">
                    @if ((partner?.url && partner?.url !== '/') || (product?.url && product?.url !== '/')) {
                        <div>
                            <button (click)="goToEShop();trackingService.trackEvent('partner Website', 'partner Website ' + partner?.name, partner?.name, partner?.id)"
                                    class="btn-validate px-5 py-1 text-normal bold"
                                    mat-raised-button>
                                Aller sur le site
                            </button>
                        </div>
                    }
                    <div class="pt-3 text-small">
                        Votre code est stocké dans votre historique de commande et vous est envoyé par email.
                    </div>
                </div>
            </div>
        }
    </div>
</div>
