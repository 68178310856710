<mat-card class="product-card border-radius-5 p-0">
    <mat-card-content class="h-100 w-100 p-0">
        <img [alt]="product?.label"
             [priority]="true"
             width="119"
             height="119"
             [ngSrc]="uiService.getProductImg(product?.id, product?.partnerId)"
             [placeholder]="placeholderImgBase64"
             (error)="uiService.handleMissingImage($event)"/>
        <div class="product-card-footer w-100 position-absolute bg-original-blue-85 color-white px-2">
            <span class="w-100 text-center text-nowrap">{{ product?.label }}</span>
        </div>
    </mat-card-content>
</mat-card>
