import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {finalize, from, Observable, Subscription} from 'rxjs';
import {Partner} from '@isifid/core';
import {PartnersService} from './partners.service';
import { PartnerHits } from '../models/catalog.model';

declare const algoliasearch: any;

@Injectable({providedIn: 'root'})
// Service used for Algolia search
export class SearchService {
    public isSearching: boolean = false;
    public searchString: string = '';
    public filteredPartners: Partner[] | undefined;
    public hideSearchEngine: boolean = false;
    private algoliaSubscription$: Subscription = new Subscription();

    constructor(
        private readonly partnersService: PartnersService
    ) {
    }

    public searchPartners(value: string): void {
        if (this.algoliaSubscription$) this.algoliaSubscription$.unsubscribe();
        this.isSearching = true;
        this.searchString = value;
        const client = algoliasearch(environment.algoliaApplicationId, environment.algoliaApiKey);
        const index = client.initIndex('partners');
        this.algoliaSubscription$ = from(
            index.search(
                value ?? '',
                {hitsPerPage: 1000}
            )
        )
            .pipe(finalize(() => this.isSearching = false))
            .subscribe({
                next: ({hits}: any) => {
                    const partners: Partner[] = [];
                    hits.forEach((s:any) => {
                        const partner = this.partnersService.partnersList.find(j => j.id === s.partnerId);
                        if (partner) partners.push(partner);
                    });
                    this.filteredPartners = partners;
                },
                error: (error) => console.error(error)
            });
    }

    public getHits(value: string): Observable<PartnerHits> {
        const client = algoliasearch(environment.algoliaApplicationId, environment.algoliaApiKey);
        const index = client.initIndex('partners');
        return from(
            index.search(
                value ?? '',
                {hitsPerPage: 1000}
            )
        ) as Observable<PartnerHits>;
    }

    public cancelSearchPartners(): void {
        if (this.algoliaSubscription$) this.algoliaSubscription$.unsubscribe();
        this.searchString = '';
        this.filteredPartners = undefined;
    }
}
