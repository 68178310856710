import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponentComponent {

}
