import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    show = false;

    start() {
        this.show = true;
    }

    stop() {
        this.show = false;
    }

}
