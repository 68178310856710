<mat-toolbar class="row g-0 bg-white">
    <div [class.padding-sidenav]="homepage" class="col text-center mx-auto overflow-x-auto">
        <span class="mx-2 mx-sm-4 mx-md-5 clickable text-small"
              routerLink="/terms-and-conditions">
            Conditions d’utilisation
        </span>
        <span class="mx-2 mx-sm-5 mx-md-5 clickable text-small"
              routerLink="/legal-notice">
            Mentions légales
        </span>
        <span class="mx-2 mx-sm-5 mx-md-5 clickable text-small"
              routerLink="/cookies">
            Politique relative aux cookies
        </span>
    </div>
</mat-toolbar>
