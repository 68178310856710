import {Component, Inject} from '@angular/core';
import {UiService} from '../../services/ui.service';
import {Product, TrackingService} from '@isifid/core';
import {DialogService} from '../../services/dialog.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import { placeholderImgBase64 } from '../../constants/placeholder-img';

export interface ProductCardDialogData {
    product: Product;
}

@Component({
    templateUrl: './product-card.dialog.html',
    styleUrls: ['./product-card.dialog.scss']
})
export class ProductCardDialog {
    env = environment;
    placeholderImgBase64: string = placeholderImgBase64;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ProductCardDialogData,
        public readonly uiService: UiService,
        public readonly dialogService: DialogService,
        public readonly trackingService: TrackingService
    ) {
    }

    getImgUrl(): string {
        if (!this.data.product.id || !this.data.product.partnerId) {
            return `${this.env.cdnUrl}/marketplace/img/image-not-available.png`;
        }
        return this.uiService.getProductImg(this.data.product.id, this.data.product.partnerId);
    }
}
