import { Component, HostBinding } from '@angular/core';
import {BootstrapService} from '../../../services/bootstrap.service';
import { Observable, delay } from 'rxjs';

@Component({
  selector: 'app-layout-normal',
  templateUrl: './layout-normal.component.html',
  styleUrl: './layout-normal.component.scss'
})
export class LayoutNormalComponent {
  isInitialised$: Observable<boolean>;

  constructor(
    private readonly bootstrapService: BootstrapService,
  ) {
    this.isInitialised$ = this.bootstrapService.isInitialised.asObservable().pipe(delay(1));
  }

  @HostBinding('class') classes = 'container-fluid d-flex flex-column px-0 m-0 py-toolbar-height max-vw-100 h-100'
}
