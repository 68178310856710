import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-price-box',
    templateUrl: './price-box.component.html',
    styleUrls: ['./price-box.component.scss']
})
export class PriceBoxComponent {
    @Input() public label: string | undefined;
    @Input() public price: number | undefined;
}
