import {Component} from '@angular/core';
import {MsConsumersService} from "@isifid/core";
import {DialogService} from "../../services/dialog.service";
import {ConsumersService} from '../../services/consumers.service';

@Component({
  templateUrl: './nps.dialog.html',
  styleUrl: './nps.dialog.scss'
})
export class NpsDialog {
    status: 'loading' | 'success' | '' = '';

    constructor(
        private readonly dialogService: DialogService,
        private readonly msConsumesService: MsConsumersService,
        private readonly consumersService: ConsumersService
    ) {
    }

    createNps(s: number): void {
        this.status = 'loading';
        this.msConsumesService.createNps(this.consumersService.consumer.id, {score: s})
            .subscribe(() => this.status = 'success')
    }

    closeDialog(): void {
        this.dialogService.closeNpsDialog();
    }
}
