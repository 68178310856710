<div class="px-3 py-5 px-lg-5 position-relative overflow-auto">
    <div class="position-absolute top-0 end-0 p-3 text-large">
        <i (click)="closeDialog()" aria-hidden="true" class="fas fa-times fa-2x clickable me-2"></i>
    </div>
    <div>
        <h4>Comment ça marche ?</h4>
        <div class="mt-3 content">
            <p>Vous avez reçu une somme de x €, valable 6 mois, destinée à commander des codes-cadeaux sur ce site.</p>
            <p>La valeur du code-cadeau de chaque partenaire est indiquée dans la galerie. Selon vos choix, vous pourrez
                donc consommer un ou plusieurs codes-cadeaux avec la somme que vous avez reçue.</p>
            <p>Après avoir commandé un code-cadeau sur ce site, vous pourrez copier ce code et vous rendre sur le site
                du partenaire choisi. Il vous suffira de coller le code dans le champ prévu à cet effet lors du
                règlement.</p>
            <p>Les codes-cadeaux n’imposent aucun minimum d’achat, sauf exception, toujours indiquée. Dans le cas où
                l’article commandé chez le partenaire est d’un montant supérieur au code-cadeau, vous devrez effectuer
                un règlement complémentaire par CB. Dans le cas inverse, la différence ne peut vous être restituée.</p>
            <p>Un seul code-cadeau par commande peut être utilisé. Chaque code-cadeau est à usage unique. Dès
                utilisation, ce partenaire disparaît de votre galerie.</p>
            <p>Tout code-cadeau commandé a une validité d’1 mois à compter de la date à laquelle vous l’avez
                commandé.</p>
        </div>
    </div>
</div>
