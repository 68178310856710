<mat-card class="gift-card gift-card-{{size}} border-radius-5 p-0">
    <mat-card-content class="h-100 w-100 p-0">
        <img [alt]="partner?.id"
             [ngSrc]="imgUrl"
             [priority]="true"
             width="119"
             height="157"
             [placeholder]="placeholderImgBase64"
             (error)="uiService.handleMissingImage($event)"/>

        <div class="w-100 d-flex justify-content-between position-absolute top-0 p-2">
            @for (partnerOffer of partnerOffers; track partnerOffer.id) {
                <div class="px-1 price bold rounded bg-white border" [class.opacity-60]="partnerOffer.type === 1 && partnerOffers.length > 1">
                    {{partnerOffer.price}} € {{(partnerOffer.type === 1 && partnerOffers.length > 1) ? '*' : ''}}
                </div>
            }
        </div>
        <div class="gift-card-footer w-100 position-absolute text-center color-white">
            <img (error)="uiService.handleMissingImage($event)"
                 [alt]="partner?.name"
                 [priority]="true"
                 [placeholder]="placeholderImgBase64"
                 [ngSrc]="env.cdnUrl + '/partners/resources/' + partner?.resourcesUuid + '/logo_200x200.png'"
                 class="bg-white circle"
                 height="40"
                 width="40"/>
            <div class="partner-name px-2 position-absolute w-100">
                <span class="w-100 text-center text-nowrap">{{ partner?.name }}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>
