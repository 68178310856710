<div class="border-top w-100 catalog-item" data-cy="catalog-item">
    <div class="d-flex justify-content-center align-items-center catalog-text">
        <div class="w-120">
            <img (error)="uiService.handleMissingImage($event)"
                 [alt]="catalogItem.partnerName"
                 [ngSrc]="env.cdnUrl + '/partners/resources/' + formatPartner(catalogItem).resourcesUuid + '/logo_200x200.png'"
                 class="bg-white circle mat-elevation-z2"
                 height="100"
                 width="100"/>
        </div>
        <div class="info-text d-block">
            <div class="text-group">
                <p class="bold partner-name">{{ catalogItem.partnerName | titlecase }}</p>
                <div class="p-group">
                    @if (catalogItem.partnerProducts.length) {
                        @if(isProduct){
                            <p class="text-decoration-underline image-down" (click)="activeProduct()">-&nbsp;&nbsp;Cacher les produits</p>
                        } @else {
                            <p class="opacity-75 image-down" (click)="activeProduct()">+&nbsp;&nbsp;Voir les produits</p>
                        }
                    } @else {
                        <p class="opacity-75 image-down">Aucun produit</p>
                    }
                    <p class="opacity-75 link-text" (click)="goToPartnerUrl(catalogItem)">Aller sur le site</p>
                </div>
            </div>
            <p class="fs-6 text-truncate text-description">{{ catalogItem.partnerDescription }}</p>
            <div class="d-flex flex-row text-nowrap fs-6 w-100 overflow-x-auto">
                @for (partnerOffer of catalogItem.partnerOffers; track partnerOffer.type) {
                    <div data-cy="partner-offer">
                        @switch (partnerOffer.type) {
                            @case (0) {
                                <button class="btn mx-1 btn-offers-1">
                                    {{ partnerOffer.price }} &euro;
                                </button>
                            }
                            @case (1) {
                                <button class="btn mx-1 btn-offers-2">
                                    {{ partnerOffer.price }} &euro; *
                                </button>
                            }
                            @case (2) {
                                <button class="btn mx-1 btn-offers-3">
                                    {{ partnerOffer.price }} &euro;
                                </button>
                            }
                        }
                    </div>
                }
            </div>
        </div>
    </div>
    @if ((isDesktop$ | async) || isProduct) {
        <div class="catalog-product">
            <div class="image-group">
                <app-catalogue-products [catalogItem]="catalogItem"/>
            </div>
        </div>
    }
</div>