import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CatalogueRoutingModule} from './catalogue-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {CatalogueComponent} from './catalogue/catalogue.component';
import {ProductsComponent} from './products/products.component';
import {CatalogueItemComponent} from './catalogue-item/catalogue-item.component';
import {CatalogueSearchComponent} from './catalogue-search/catalogue-search.component';

@NgModule({
    declarations: [
        CatalogueComponent,
        ProductsComponent,
        CatalogueItemComponent,
        CatalogueSearchComponent
    ],
    imports: [
        CommonModule,
        CatalogueRoutingModule,
        SharedModule,
        FormsModule,
        MatButtonModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatIcon,
        MatMenuModule
    ]
})
export class CatalogueModule {
}
