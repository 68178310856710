import {Component, Inject} from '@angular/core';
import {TrackingService} from '@isifid/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UiService} from '../../services/ui.service';
import {DialogService} from '../../services/dialog.service';
import {environment} from '../../../../environments/environment';
import { placeholderImgBase64 } from '../../constants/placeholder-img';
import {CatalogProductDTO} from '../../models/catalog.model';

export interface ProductCardCatalogData {
  product: CatalogProductDTO;
  uuid: string;
}

@Component({
  templateUrl: './catalog-card-dialog.component.html',
  styleUrl: './catalog-card-dialog.component.scss'
})
export class CatalogCardDialogComponent {
  env = environment;
  placeholderImgBase64: string = placeholderImgBase64;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: ProductCardCatalogData,
      public readonly uiService: UiService,
      public readonly dialogService: DialogService,
      public readonly trackingService: TrackingService
  ) {
  }

  get getImgUrl(): string {
    const size = '200x200';
    if (!this.data.product.id || !this.data.uuid) {
        return `${this.env.cdnUrl}/marketplace/img/image-not-available.png`;
    }
    return `${environment.cdnUrl}/partners/resources/${this.data.uuid}/product_${this.data.product.id}_${size}.jpg`;
  }
}
