<div class="dialog-wrapper">
    <div class="close-button clickable" (click)="closeDialog()">
        <i class="far fa-times-circle"></i>
    </div>
    @if (!status) {
        <div class="h-100 d-flex flex-column justify-content-between">
            <div class="nps-question">
                Comment noteriez-vous notre sélection de partenaires ?
            </div>
            <div class="d-flex justify-content-between">
                @for (s of [1, 2, 3, 4, 5]; track s) {
                    <div class="score-button clickable d-flex justify-content-center align-items-center" (click)="createNps(s)">
                        <span class="rounded-circle d-flex justify-content-center align-items-center">{{ s }}</span>
                    </div>
                }
            </div>
        </div>
    } @else if (status === 'success') {
        <div class="d-flex flex-column justify-content-center align-items-center h-100 gap-2">
            <i class="far fa-check-circle"></i>
            <div>Merci pour votre réponse</div>
        </div>
    } @else if (status === 'loading') {
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <mat-spinner [strokeWidth]="2" [diameter]="32"></mat-spinner>
        </div>
    }
</div>
