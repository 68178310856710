import {Component, Input, OnInit} from '@angular/core';
import {UiService} from '../../services/ui.service';

@Component({
    selector: 'app-img',
    templateUrl: './img.component.html'
})
export class ImgComponent implements OnInit {
    @Input() src: string = '';
    @Input() alt: string = '';
    @Input() cssClasses: string = '';
    @Input() imgId: string = '';
    @Input() routerLinkUrl: string = '';
    secondSrc: string = '';
    loadedSrc: string = '';

    constructor(
      public uiService: UiService
    ) {
    }

    ngOnInit() {
        this.secondSrc = this.uiService.handleMissingLogo({target: {src: this.src}} as any);
    }

    onLoadImage(event: any): void {
        if (!this.loadedSrc) this.loadedSrc = event.target.src;
    }
}
