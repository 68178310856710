import {Injectable} from '@angular/core';
import {CacheService, MsPartnersService, MsToolsMarketplaceService, Order, Partner, PartnerOffer, Product, Voucher} from '@isifid/core';
import {delay, forkJoin, map, Observable, of, tap} from 'rxjs';
import {CatalogEntryDTO} from '../models/catalog.model';

@Injectable({providedIn: 'root'})
export class PartnersService {
    public partnersList!: Array<Partner>;
    private offersList!: Array<PartnerOffer>;
    private products!: Array<Product>;

    constructor(
        private readonly msToolsMarketplaceService: MsToolsMarketplaceService,
        private readonly msPartnersService: MsPartnersService,
        private readonly cacheService: CacheService
    ) {
    }

    destroy() {
        this.partnersList = [];
        this.offersList = [];
    }

    initEntitiesFromCache() {
        this.partnersList = this.cacheService.getContent('partners_list');
        this.offersList = this.cacheService.getContent('offers_list');
    }

    public init(clientId: number, forceRefresh: boolean = false): Observable<any> {
        return forkJoin([
            this.initPartnersList(clientId, forceRefresh),
            this.initOffersList(forceRefresh),
            this.initProducts()
        ]);
    }

    public initDemo(clientId: number): Observable<any> {
        return new Observable((o) => {
            this.msToolsMarketplaceService.getCatalogByClientId(clientId).subscribe((catalog: Array<CatalogEntryDTO>) => {
                catalog.sort((a, b) => a.partnerName.localeCompare(b.partnerName));

                const partners: any[] = [];
                catalog.forEach((catalogItem) => {
                    partners.push({
                        id: catalogItem.partnerId,
                        name: catalogItem.partnerName,
                        resourcesUuid: catalogItem.partnerResourcesUuid,
                        partnerOffers: catalogItem.partnerOffers,
                        partnerProducts: catalogItem.partnerProducts,
                        description: catalogItem.partnerDescription,
                        url: catalogItem.partnerUrl,
                        restrictions: catalogItem.partnerRestrictions,
                        headOfficeAddressComplement: '', country: '', status: '',
                        shortDesc: '', createdAt: '', billingMethod: ''
                    });
                });
                this.partnersList = partners;

                this.offersList = this.getOffers(catalog);

                o.next();
                o.complete();
            });
        });
    }

    // Where all policies on partner offers to display are applied
    // We update partners and offers list
    public filter(rewards: Map<number, number>, orders: Order[], demoMode: boolean = false): void {
        const rewardTypeMax = Math.max(...rewards.keys());

        // First we filter all offers
        this.offersList = this.offersList.filter((offer: PartnerOffer) => {
            // Filter by reward type (regular, premium, platinum)
            if (offer.type > rewardTypeMax) return false;

            // Remove maxi bons for non regular offers
            if(rewardTypeMax > 1 && offer.type === 1) return false;

            // The lowest rewards has the max amount
            // So we remove all amount above this one
            if (offer.price > Number(rewards.get(1))) return false;

            // Check if offers can be ordered multiple times
            if (offer.multipleOrdersAllowed) return true;

            // Remove all offers with a partner already ordered
            else return !orders.find((order: Order) => order.partnerId === offer.partnerId && order.status == 'active');
        });

        const partners: Partner[] = [];
        const offers: PartnerOffer[] = [];

        this.partnersList.forEach((partner: Partner) => {
            const currentPartnerOffers = this.offersList.filter((offer: PartnerOffer) => offer.partnerId === partner.id);

            // It's a partner without offers, we skip
            if (!currentPartnerOffers) return;

            // Platinum
            if (rewards.has(3)) {
                // We only keep affordable offers with matching type
                const offersFiltered =
                    currentPartnerOffers.filter((offer: PartnerOffer) => offer.type === 3 && offer.price <= Number(rewards.get(3)))
                        .sort((a, b) => b.price - a.price);

                // We found a matching offer
                // We save the offer, the partner and we move the next one
                if (offersFiltered.length) {
                    offers.push(offersFiltered[0]);
                    partners.push(partner);
                    return;
                }
            }

            // Premium
            if (rewards.has(2)) {
                // We only keep affordable offers with matching type
                const offersFiltered =
                    currentPartnerOffers.filter((offer: PartnerOffer) => offer.type === 2 && offer.price <= Number(rewards.get(2)))
                        .sort((a, b) => b.price - a.price);

                // We found a matching offer
                // We save the offer, the partner and we move the next one
                if (offersFiltered.length) {
                    offers.push(offersFiltered[0]);
                    partners.push(partner);
                    return;
                }
            }

            // Regular and maxi bon
            if (rewards.has(1)) {
                const offersFilteredType1 = currentPartnerOffers.filter((offer: PartnerOffer) => offer.type === 1)
                    .sort((a, b) => b.price - a.price);
                const offersFilteredType0 = currentPartnerOffers.filter((offer: PartnerOffer) => offer.type === 0)
                    .sort((a, b) => b.price - a.price);

                if (offersFilteredType0.length) offers.push(offersFilteredType0[0]);
                if (offersFilteredType1.length) offers.push(offersFilteredType1[0]);
                if (offersFilteredType0.length || offersFilteredType1.length) partners.push(partner);
            }
        });

        this.offersList = offers.sort((a, b) => a.price - b.price);
        this.partnersList = partners;

        if (!demoMode) {
            // We set back the content with filtered lists
            this.cacheService.addPermanentContent('partners_list', this.partnersList);
            this.cacheService.addPermanentContent('offers_list', this.offersList);
        }
    }

    public getPartnersList(): Array<Partner> {
        return this.partnersList;
    }

    public getPartner(partnerId: number): Partner | undefined {
        return this.partnersList.find(p => p.id === partnerId);
    }

    // Where we apply display policy for vouchers
    public getPartnerOffers(partnerId: number, applyDisplayPolicy = true): Array<PartnerOffer> {
        let offers = this.offersList.filter(offer => offer.partnerId === partnerId);
        // If only one offer no need to filter or no need to apply display policies
        if (offers.length < 2 || !applyDisplayPolicy) return offers;

        // Sort by type so we use the one with the highest type as the most important
        // [...offers] creates a copy so we don't update original offers list
        const offerToKeep = [...offers].sort((a, b) => b.type - a.type)[0];

        // We use the type of the first offer found to keep only these one
        // Excepted if type is 0 or 1, we keep both
        if (offerToKeep.type > 1) offers = offers.filter(offer => offer.type === offerToKeep.type);
        return offers;
    }

    public getPartnersProducts(partners: Array<Partner>): Observable<Array<Product>> {
        const offers = new Map();

        partners.forEach((partner: Partner) => {
            // Filter out partners which only have a maxi(1) voucher
            // We don't want to display products when the consumer has to add a lot of money
            const partnerOffers = this.getPartnerOffers(partner.id, true).filter(po => po.type != 1);
            if (partnerOffers.length) offers.set(partner.id, partnerOffers);
        });

        return of(this.products).pipe(
            delay(300),
            map(products => {
                return products
                    .filter(s => partners.some(t => t.id === s.partnerId) && this.getOfferForProduct(offers.get(s.partnerId), s))
                    .sort(() => Math.random() - .5);
            })
        );
    }

    public getOfferForProduct(offers: Array<PartnerOffer>, product: Product): PartnerOffer | null {
        if (!product.price || !offers) return null;

        // We consider a maximum variation of 20%
        const rate = 20;

        for (const offer of offers) {
            // Check if the offer price is close enough to the product price
            const ratio = product.price / offer.price;
            if (ratio >= (1 - rate / 100) && ratio <= (1 + rate / 100)) {
                return offer;
            }
        }

        // We didn't find an offer for this product
        return null;
    }

    public getDemoPartnersProducts(partners: Array<Partner>): Observable<Array<Product>> {
        const products: Array<Product> = [];

        partners.forEach((partner: any) => {
            if (!(partner.partnerProducts?.length)) return;

            // Filter out partners which only have a maxi(1) voucher
            // We don't want to display products when the consumer has to add a lot of money
            const offers = this.getPartnerOffers(partner.id, true).filter(po => po.type != 1);
            if (!offers.length) return;

            partner.partnerProducts.forEach((product: Product) => {
                const offer = this.getOfferForProduct(offers, product);
                if (offer) {
                    product.partnerId = partner.id;
                    products.push(product);
                }
            });
        });

        // Shuffle products
        products.sort(() => Math.random() - .5);

        return of(products).pipe(delay(500));
    }

    public getVoucher(voucherId: number): Observable<Voucher> {
        return this.msPartnersService.getVoucher(voucherId);
    }

    public getPartnerFromApi(partnerId: number): Observable<Partner> {
        return this.msPartnersService.getPartner(partnerId);
    }

    public getPartnerOfferFromApi(offerId: number): Observable<PartnerOffer[]> {
        const filter = {
            id: offerId
        };
        return this.msPartnersService.getOffers(filter);
    }

    private initPartnersList(clientId: number, forceRefresh: boolean = false): Observable<any> {
        if (!forceRefresh && this.partnersList?.length) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        return new Observable((o) => {
            this.msPartnersService.getPartners([], {clientId: clientId, status: 'active'}).subscribe(
                {
                    next: (partnersList) => {
                        this.partnersList = partnersList;
                        this.cacheService.addPermanentContent('partners_list', this.partnersList);
                        o.next();
                        o.complete();
                    }, error: () => o.error()
                });
        });
    }

    private initOffersList(forceRefresh: boolean = false): Observable<any> {
        if (!forceRefresh && this.offersList?.length) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        return new Observable((o) => {
            this.msPartnersService.getOffers({status: 'active'}).subscribe({
                next: (offersList) => {
                    this.offersList = offersList;
                    this.cacheService.addPermanentContent('offers_list', this.offersList);
                    o.next();
                    o.complete();
                }, error: () => o.error()
            });
        });
    }

    private initProducts(): Observable<Product[]> {
        return this.msPartnersService.getAllProducts('active')
            .pipe(tap(products => this.products = products));
    }

    private getOffers(catalog: CatalogEntryDTO[]) : any[] {
        const offers: any[] = [];
        catalog.forEach((catalogItem) => {
            if (catalogItem.partnerOffers) {
                catalogItem.partnerOffers.forEach((offer) => {
                    offers.push({
                        price: offer.price,
                        type: offer.type,
                        partnerId: catalogItem.partnerId,
                        status: 'active'
                    });
                });
            }
        });
        return offers;
    }
}
