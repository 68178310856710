import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {CacheService, CapitalizePipe, MarketplaceSettings, Partner, Product, Tag} from '@isifid/core';
import {MarketplaceService} from './marketplace.service';
import {Title} from '@angular/platform-browser';
import {PartnersService} from './partners.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {BehaviorSubject, forkJoin, map, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TagWithPartnersProducts, UpdateTagsWithPartnersProducts} from '../models/tag.model';

@Injectable({
    providedIn: 'root'
})
export class UiService {
    env = environment;
    public logoUrl: string = '';
    public faviconUrl: string = '';
    public loadingPartners: boolean = false;
    public currentTagSubject!: BehaviorSubject<Tag>;
    public tagsWithPartnersProductsSubject: BehaviorSubject<Array<TagWithPartnersProducts>>;
    public currentTagPositionY: number = 0;
    private marketplaceSettings!: MarketplaceSettings;
    private RANDOM_RANKING_METHOD = 'random';

    constructor(
        private readonly cacheService: CacheService,
        private readonly capitalizePipe: CapitalizePipe,
        private clipboard: Clipboard,
        private readonly marketplaceService: MarketplaceService,
        private readonly partnersService: PartnersService,
        private router: Router,
        private snackBar: MatSnackBar,
        private titleService: Title
    ) {
        this.currentTagSubject = new BehaviorSubject<Tag>(new Tag());
        this.tagsWithPartnersProductsSubject = new BehaviorSubject<Array<TagWithPartnersProducts>>([]);
    }

    destroy() {
        this.marketplaceSettings = this.marketplaceService.getSettings();
        this.logoUrl = '';
        this.faviconUrl = '';
    }

    initEntitiesFromCache() {
        this.marketplaceSettings = this.marketplaceService.getSettings();
        this.logoUrl = this.cacheService.getContent('logo_url');
        this.faviconUrl = this.cacheService.getContent('favicon_url');
    }

    selectTag(tagId: number = -1, refresh: boolean = false): Observable<null> {
        // Make sure we have tags
        if (!this.marketplaceService.getTags()?.length) return of(null);

        // If refresh is not set, we'll expect a different tagId so we don't do the same work twice
        if (!refresh && this.currentTagSubject?.value?.id === tagId) return of(null);

        // Init with tagId value, -1 will never be found
        let tag = this.marketplaceService.getTags().find((t) => t.id === tagId);

        // Set up tag 0 as the default one
        if (!tag) tag = this.marketplaceService.getTags()[0];

        if (tagId > 0) this.router.navigate([], {queryParams: {tagId: tagId}}).then();

        this.currentTagSubject.next(tag);

        // Get partners and products for the tag
        return this.getPartnersProducts(tag, refresh);
    }

    init(): Observable<any> {
        this.marketplaceSettings = this.marketplaceService.getSettings();

        return new Observable((o) => {
            this.setFaviconUrl();
            this.initLogoUrl().subscribe(() => {
                this.setTitlePage();
                this.setCustomCss();
                o.next();
                o.complete();
            });
        });
    }

    public clearTagsWithPartnersProducts(): void {
        this.tagsWithPartnersProductsSubject.next([]);
    }

    public getPartnersByTagId(tagId: number): Array<Partner> {
        return this.tagsWithPartnersProductsSubject.value.find(s => s.tag?.id === tagId)?.partners ?? [];
    }

    public getProductsByTagId(tagId: number): Array<Product> {
        return this.tagsWithPartnersProductsSubject.value.find(s => s.tag?.id === tagId)?.products ?? [];
    }

    public handleMissingImage(event: ErrorEvent) {
        // If 404 display image-not-available.png
        (event.target as HTMLImageElement).src = environment.cdnUrl + '/marketplace/img/image-not-available.png';
    }

    public handleMissingLogo(event: ErrorEvent):string {
        const currentUrl = (event.target as HTMLImageElement).src;

        let newUrl = environment.cdnUrl + '/marketplace/img/image-not-available.png';
        if (currentUrl.endsWith('.png') && currentUrl !== newUrl) {
            newUrl = currentUrl.replace('.png', '.jpg');
        }

        (event.target as HTMLImageElement).src = newUrl;
        return newUrl;
    }

    public getProductImg(
        productId?: number, partnerId?: number, partnerResourceUuid: string | undefined = undefined, small: boolean = true
    ) {
        if (!partnerResourceUuid && partnerId) {
            const partner: Partner | undefined = this.partnersService.getPartner(partnerId);
            if (!partner?.resourcesUuid) return '';
            partnerResourceUuid = partner.resourcesUuid;
        }

        // Default is small
        let size = '200x200';
        if (!small) size = '400x400';

        // Only serves cached images on production
        let src = environment.production ?
            `/partners/resources/${partnerResourceUuid}/product_${productId}_${size}.jpg` :
            `/not-cached/partners/resources/${partnerResourceUuid}/product_${productId}_${size}.jpg`;
        src = environment.cdnUrl + src;
        return src;
    }

    public getPartnerLogoImg(partner: Partner | undefined): string {
        if (!partner?.resourcesUuid) return '';
        return `${environment.cdnUrl}/partners/resources/${partner.resourcesUuid}/logo_200x200.png`;
    }

    public copyToClipboard(data: string, eventTarget: any) {
        this.clipboard.copy(data);
        const textContent = eventTarget.textContent;
        eventTarget.textContent = 'Code copié !';
        eventTarget.disabled = true;
        setTimeout(() => {
            eventTarget.textContent = textContent;
            eventTarget.disabled = false;
        }, this.env.duration);
    }

    public handleError() {
        this.snackBar.open('Une erreur est survenue, veuillez ressayer plus tard', 'X', {duration: this.env.duration});
    }

    public shufflePartners(partners: Array<Partner>): Array<Partner> {
        return partners
            .map(value => ({value, randomSort: Math.random()}))
            .sort((a, b) => a.randomSort - b.randomSort)
            .map(({value}) => value);
    }

    public getPartnersProducts(tag: Tag, refresh?: boolean, isPreviousTag?: boolean): Observable<null> {
        // If products was already loaded for the tagId, return
        if (this.tagsWithPartnersProductsSubject.value.find(s => s.tag?.id === tag.id) && !refresh) return of(null);
        let partners = this.marketplaceService.getPartnersByTagId(tag.id);
        if (tag.rankingMethod && tag.rankingMethod === this.RANDOM_RANKING_METHOD) partners = this.shufflePartners(partners);
        // If there is no partners, return
        if (partners.length === 0) return of(null);
        this.loadingPartners = true;
        const partnerProductsObs: Observable<Product[]>[] = [];
        const allTags = this.marketplaceService.getTags();
        let tags: Tag[] = [];
        const tagIndex = allTags.indexOf(tag);
        if (refresh) {
            tags = tagIndex < 2 ? allTags.slice(0, 3) : allTags.slice(tagIndex - 2, tagIndex + 3);
        } else if (isPreviousTag) {
            tags = tagIndex < 1 ? [allTags[0]] : [allTags[tagIndex], allTags[tagIndex - 1]];
        } else {
            tags = allTags.slice(tagIndex, tagIndex + 2);
        }
        const partnersArray: Partner[][] = [];
        tags.map(tag => {
            let partners = this.marketplaceService.getPartnersByTagId(tag.id);
            if (tag.rankingMethod && tag.rankingMethod === this.RANDOM_RANKING_METHOD) partners = this.shufflePartners(partners);
            partnersArray.push(partners);
            partnerProductsObs.push(
                this.marketplaceService.demoMode ?
                    this.partnersService.getDemoPartnersProducts(partners) :
                    this.partnersService.getPartnersProducts(partners)
            );
        });
        // If there is no partners, return
        if (partnersArray.length === 0) return of(null);

        return this.updateTagsWithPartnersProductsSubject({
            partnerProductsObs, tags, partnersArray, isPreviousTag
        });
    }

    private setTitlePage(): void {
        let clientName = 'MarketPlace';
        if (this.marketplaceSettings?.clientName) {
            clientName = this.capitalizePipe.transform(this.marketplaceSettings.clientName);
        }
        this.titleService.setTitle('MarketPlace ' + clientName);
    }

    private setCustomCss() {
        if (!this.marketplaceSettings.customCss) return;

        // Add custom css from marketplaceSettings
        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(this.marketplaceSettings.customCss));
        head.appendChild(style);
    }

    private initLogoUrl() {
        if (this.logoUrl) return of(null);

        return new Observable((o) => {
            if (this.marketplaceSettings.logoUuid) {
                this.logoUrl = `${environment.cdnUrl}/static/${this.marketplaceSettings.logoUuid}.png`;
                if (!this.marketplaceService.demoMode) this.cacheService.addPermanentContent('logo_url', this.logoUrl);
                o.next();
                o.complete();
            } else if (this.marketplaceService.getClient()?.logoFileName) {
                this.logoUrl = `${environment.cdnUrl}/static/${this.marketplaceService.getClient()?.logoFileName}`;
                if (!this.marketplaceService.demoMode) this.cacheService.addPermanentContent('logo_url', this.logoUrl);
                o.next();
                o.complete();
            } else {
                this.logoUrl = `${environment.cdnUrl}/static/acd16365-2c90-4aaf-92d3-ee9ab4bd98b6.png`;
                if (!this.marketplaceService.demoMode) this.cacheService.addPermanentContent('logo_url', this.logoUrl);
                o.next();
                o.complete();
            }
        });
    }

    private setFaviconUrl() {
        const favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');
        if (!favIcon) return;

        if (!this.faviconUrl) {
            this.faviconUrl = '/favicon.ico';
            if (this.marketplaceService.getClient()?.faviconFileName) {
                this.faviconUrl = `${environment.cdnUrl}/static/${this.marketplaceService.getClient()?.faviconFileName}`;
            }
            if (!this.marketplaceService.demoMode) this.cacheService.addPermanentContent('favicon_url', this.faviconUrl);
        }
        favIcon.href = this.faviconUrl;
    }

    private updateTagsWithPartnersProductsSubject(payload: UpdateTagsWithPartnersProducts): Observable<null> {
        const {partnerProductsObs, tags, partnersArray, isPreviousTag} = payload;
        return forkJoin(partnerProductsObs)
            .pipe(
                map((productsArray: Product[][]) => {
                    let list: TagWithPartnersProducts[] = this.tagsWithPartnersProductsSubject.value;
                    productsArray.map((products, i) => {
                        products = this.marketplaceService.filterProducts(products, tags[i]);
                        list = isPreviousTag ?
                            [{tag: tags[i], partners: partnersArray[i], products}, ...list] :
                            [...list, {tag: tags[i], partners: partnersArray[i], products}];
                    });
                    this.currentTagPositionY = (document.getElementById(`${this.currentTagSubject.value.id}`)?.offsetTop ?? 0) -
                        window.scrollY;
                    this.tagsWithPartnersProductsSubject.next(list);
                    this.loadingPartners = false;
                    return null;
                })
            );
    }
}
