import {Component, Input} from '@angular/core';
import {LoaderService} from '../../services/loader.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html'
})
export class LoaderComponent {
    mode: ProgressSpinnerMode;
    @Input() value: any;

    constructor(
        public readonly loaderService: LoaderService,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        if (this.value) {
            this.mode = 'determinate';
        } else {
            this.mode = 'indeterminate';
        }
    }

    getLoaderStatus(): boolean {
        return this.loaderService.show;
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }
}
