import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    homepage: boolean = false;

    constructor(
        private readonly route: Router
    ) {
        this.homepage = this.route.url.startsWith('/home');
    }

    ngOnInit() {
        this.route.events.forEach(() => {
            this.homepage = this.route.url.startsWith('/home');
        }).then();
    }
}
