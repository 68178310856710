import { Component, DestroyRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, switchMap, forkJoin, Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarketplaceSettings } from '@isifid/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { CatalogEntryDTO } from 'src/app//shared/models/catalog.model';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
    selector: 'app-catalogue',
    templateUrl: './catalogue.component.html',
    styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {
    catalog: Array<CatalogEntryDTO & {weight: number}> = [];
    filteredCatalog: Array<CatalogEntryDTO & {weight: number}> = [];
    selectedValue: string = 'alpha_1';
    loaded: boolean = false;
    isSearching: boolean = true;
    sort: Array<{value: string, viewValue: string}> = [
        {value: 'alpha_1', viewValue:'Alphabétique A - Z'},
        {value: 'alpha_2', viewValue:'Alphabétique Z - A'},
        {value: 'popular', viewValue:'Les plus populaires'},
        {value: 'recent_1', viewValue:'Les plus récents'},
        {value: 'recent_2', viewValue:'Les plus anciens'},
    ];

    private algoliaSubscription$: Subscription = new Subscription();
    
    constructor(
        public readonly searchService: SearchService,
        private readonly marketplaceService: MarketplaceService,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private destroyRef: DestroyRef,
    ) {
        this.loaderService.start();
    }

    ngOnInit(): void {
        this.initCatalog();
    }

    sortCatalog(event: string): void {
        this.selectedValue = event;
        switch (event) {
            case 'alpha_1':
                this.filteredCatalog.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
                break;
            case 'alpha_2':
                this.filteredCatalog.sort((a, b) => b.partnerName.localeCompare(a.partnerName));
                break;
            case 'recent_1':
                this.filteredCatalog.sort((a, b) => b.partnerCreatedAt.toString().localeCompare(a.partnerCreatedAt.toString()));
                break;
            case 'recent_2':
                this.filteredCatalog.sort((a, b) => a.partnerCreatedAt.toString().localeCompare(b.partnerCreatedAt.toString()));
                break;
            case 'popular':
                this.filteredCatalog.sort((a, b) => (b.weight - a.weight));
                break;
            default:
                break;
        }
    }

    searchText(result: string): void {
        this.isSearching = true;
        if (this.algoliaSubscription$) this.algoliaSubscription$.unsubscribe();
        this.algoliaSubscription$ = this.searchService.getHits(result)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
            next: res => {
                this.filteredCatalog = this.catalog.filter(s => res.hits.find(j => j.partnerId === s.partnerId));
                this.sortCatalog(this.selectedValue);
                this.isSearching = false;
            },
            error: error => {
                console.error(error);
                this.isSearching = false;
            }
        });
    }

    private initSettings(): Observable<MarketplaceSettings | null> {
        const settings = this.marketplaceService.getSettings();
        return settings
            ? of(settings)
            : this.marketplaceService.getSettingsByDomain();
    }

    private initCatalog(): void {
        this.initSettings()
            .pipe(
                // Both catalog api and algoria search should not return any error.
                // If it return error, page should be redirected to logout.
                switchMap((settings) => forkJoin([
                    this.marketplaceService.getCatalogByClientId(settings!.clientId),
                    this.searchService.getHits('')
                ])),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: ([catalog, hits]) => {
                    this.catalog = catalog.filter((catalogItem: CatalogEntryDTO & {weight: number}) => {
                        catalogItem.partnerOffers = catalogItem.partnerOffers.filter(offer => !(offer.price === undefined || offer.type === undefined || offer.type > 2));
                        catalogItem.weight = hits.hits.find(hit => hit.partnerId === catalogItem.partnerId)?.weight ?? 0;
                        return !!catalogItem.partnerOffers.length;
                    })
                    // Sort and copy in filteredCatalog
                    this.catalog.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
                    this.filteredCatalog = this.catalog;
                    this.loaded = true;
                    this.isSearching = false;
                },
                error: error => {
                    console.error(error);
                    this.isSearching = false;
                    this.router.navigate(['/logout']).then()
                }
            });
    }
}
