import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CatalogueComponent} from './catalogue/catalogue.component';
import {TrackGuard} from '@isifid/core';

const routes: Routes = [
    {
        path: '',
        canActivate: [TrackGuard],
        component: CatalogueComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CatalogueRoutingModule {
}
