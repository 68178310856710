<div [class]="'search-container ' + search_class" [formGroup]="formGroup">
    <input
        [id]="search_id"
        (keyup)="openSearchPartnersDialog($event)"
        (click)="openSearchPartnersDialog()"
        placeholder="Rechercher un partenaire, un produit ou ce que vous voulez"
        class="search-input"
        formControlName="searchString"
        [value]="formGroup.controls.searchString.value"
    />
    <div [class]="'icons-wrapper ' + search_icon">
        @if (formGroup.controls.searchString.value) {
            <mat-icon class="cancel-icon clickable" (click)="cancelSearch()">cancel</mat-icon>
        }
        <mat-icon class="search-icon clickable rounded-circle" (click)="openSearchPartnersDialog()">search</mat-icon>
    </div>
</div>