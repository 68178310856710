<div class="search-container">
    <input
        (keyup)="partnerSearch()"
        [(ngModel)]="searchString"
        placeholder="Rechercher un partenaire, un produit ou ce que vous voulez"
        class="search-input"
    />
    <div class="icons-wrapper">
        @if (!!searchString) {
            <mat-icon class="cancel-icon clickable" (click)="cancelSearch()">cancel</mat-icon>
        }
        <mat-icon class="search-icon clickable rounded-circle" (click)="partnerSearch()">search</mat-icon>
    </div>
</div>