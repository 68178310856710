<mat-dialog-content (mouseleave)="dialogService.closeProductCardDialog()"
                    (window:resize)="dialogService.closeProductCardDialog()"
                    class="product-card-dialog d-flex flex-column justify-content-end border-radius-5 m-0 p-0">
    <img alt="product-card-img"
         class="product-card-img"
         [priority]="true"
         width="260"
         height="345"
         [ngSrc]="getImgUrl()"
         [placeholder]="placeholderImgBase64"
         (error)="uiService.handleMissingImage($event)"/>

    <div class="dialog-content position-absolute">

        <div class="product-card-dialog-footer text-center w-100 color-white py-3 position-absolute bottom-0">
            <p class="px-2 mb-3 text-small text-truncate">{{ data.product.label }}</p>

            <div class="px-3">
                <button (click)="dialogService.openOrderCardDialog(undefined, undefined, data.product);
                             trackingService.trackEvent('product', 'select product partnerId ' + data.product.partnerId, 'select-product ' + data.product.label)"
                        class="btn-validate text-normal bold w-100 px-3 py-1" mat-raised-button>
                    Sélectionner ce produit
                </button>
            </div>
        </div>


    </div>

</mat-dialog-content>
