<div class="color-dark-grey">
    @if (loaded) {
        <div class="position-fixed d-block bg-white pt-5 pb-4 catalogue-filter-bar header">
            <h1 class="bold text-huge mb-4 catalog-title">Les partenaires</h1>
            <div class="d-flex vouchers">
                <p class="vouchers-title">Types de bons:</p>
                <div class="d-block ms-3">
                    <div class="d-flex gap-2">
                        <button class="btn btn-offers px-2 btn-offers-1">Regular</button>
                        <button class="btn btn-offers px-2 btn-offers-2">Premium</button>
                        <button class="btn btn-offers px-2 btn-offers-3">Maxi *</button>
                    </div>
                    <p class="opacity-75 mt-1">* Avec minimum d’achat</p>
                </div>
            </div>
            <div class="px-2 catalog-header">
                <app-catalogue-search (searchText)="searchText($event)" class="search" />
                <button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="mat-select btn d-flex justify-content-start align-items-center">
                    <span>Trier par</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    @for (item of sort; track item.value) {
                        <button mat-menu-item class="check-button" (click)="sortCatalog(item.value)">
                            <mat-icon>
                                @if (item.value === selectedValue) {check}
                            </mat-icon>
                            <span>{{item.viewValue}}</span>
                        </button>
                    }
                </mat-menu>
            </div>
        </div>
        @if (isSearching) {
            <app-loader class="spinner text-center w-100" />
        }
        <div class="px-4 catalog-body" [ngClass]="{'opacity-0': isSearching}">
            @for (catalogItem of filteredCatalog; track catalogItem.partnerId) {
                <app-catalogue-item [catalogItem]="catalogItem" />
            } @empty {
                <div class="w-100 h-auto d-flex justify-content-center align-items-center">
                    <p class="not-found">Aucun résultat trouvé.</p>
                </div>
            }
        </div>
    }
</div>
