import {Injectable, inject} from '@angular/core';
import {
    Consumer,
    ConsumerNps,
    LabelledReward,
    MsConsumersService,
    MsServicesSponsorshipService,
    Order,
    PartnerOffer,
    Reward,
    SponsorshipUser
} from '@isifid/core';
import {catchError, forkJoin, Observable, of, switchMap, tap} from 'rxjs';
import {MarketplaceService} from './marketplace.service';

@Injectable({providedIn: 'root'})
export class ConsumersService {
    consumer: Consumer = new Consumer();
    sponsorshipUser: SponsorshipUser | undefined;
    rewards: Array<LabelledReward> = [];
    orders: Array<Order> = [];
    askForNps: boolean = false;

    private msConsumersService = inject(MsConsumersService);
    private msServicesSponsorshipService = inject(MsServicesSponsorshipService);

    constructor(
        private readonly marketplaceService: MarketplaceService,
    ) {}

    public destroy() {
        this.consumer = new Consumer();
        this.rewards = [];
        this.orders = [];
    }

    public init(consumerId: string): Observable<any> {
        let rewardList: Array<Reward> = [];
        if (this.marketplaceService.demoMode) return of(null);
        let askForNps = false;
        if (this.marketplaceService.client?.configuration) askForNps = JSON.parse(this.marketplaceService.client.configuration)?.askForNps;
        const observers: Observable<Consumer | Order[] | Reward[] | ConsumerNps>[] = [
            this.msConsumersService.getConsumer(consumerId),
            this.msConsumersService.getOrders(consumerId),
            this.msConsumersService.getRewards(consumerId)
        ]

        if (askForNps) {
            observers.push(
                this.msConsumersService.getNps(Number(consumerId)).pipe(catchError(() => of(null))) as Observable<ConsumerNps>
            )
        }

        return forkJoin(observers).pipe(
            switchMap(([consumer, orders, rewards, consumerNps]) => {
                this.consumer = consumer as Consumer;
                this.orders = orders as Order[];
                this.rewards = [];
                rewardList = rewards as Reward[];
                this.askForNps = askForNps && !consumerNps;
                return this.msServicesSponsorshipService.getSponsorshipUserByConsumerId(consumerId)
                    .pipe(catchError(() => of(undefined)));
            }),
            tap(sponsorshipUser => {
                this.sponsorshipUser = sponsorshipUser;
                this.rewards = rewardList
                    .filter(s => s.offerId)
                    .map(s => ({...new LabelledReward(), ...s}));
            })
        );
    }

    public initDemo(): Observable<any> {
        const rewardTypeId = this.marketplaceService.demoRewardTypeId ?? 2;
        const amount = this.marketplaceService.demoAmount ?? 100;

        const reward = new LabelledReward();
        reward.status = 'active';
        reward.rewardTypeId = rewardTypeId;
        reward.amount = amount;
        reward.balance = amount;

        const consumer: Consumer = new Consumer();
        consumer.balance = reward.amount;

        this.consumer = consumer;
        this.rewards = [reward];

        this.sponsorshipUser = undefined;

        return of(null);
    }

    public getConsumer(): Consumer {
        return this.consumer;
    }

    public order(partnerOffer: PartnerOffer): Observable<Order> {
        const consumerId = this.getConsumer().id;
        const data = {
            amount: partnerOffer.price,
            partnerId: partnerOffer.partnerId,
            partnerOfferId: partnerOffer.id
        };
        return this.msConsumersService.createOrder(consumerId, data);
    }

    public sendOrderEmail(orderId: number): Observable<void> {
        return this.msConsumersService.getOrderEmail(this.getConsumer().id, orderId);
    }
}
