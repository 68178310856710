import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Partner, PartnerOffer} from '@isifid/core';
import {UiService} from '../../services/ui.service';
import {environment} from '../../../../environments/environment';
import {PartnersService} from '../../services/partners.service';
import {placeholderImgBase64} from '../../constants/placeholder-img';

@Component({
    selector: 'app-gift-card',
    templateUrl: './gift-card.component.html',
    styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent implements OnChanges {
    env = environment;
    placeholderImgBase64: string = placeholderImgBase64;
    partnerOffers: PartnerOffer[] = [];
    imgUrl: string = '';

    @Input() size: 'sm' | 'md' = 'md';
    @Input() partner: Partner | undefined;

    constructor(
        public readonly uiService: UiService,
        private readonly partnersService: PartnersService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.partner?.currentValue) {
            this.imgUrl = `${environment.cdnUrl}/partners/resources/${this.partner?.resourcesUuid}/cover_card_300x400.jpg`;
            this.partnerOffers = this.partnersService.getPartnerOffers(changes.partner.currentValue.id);
            this.partnerOffers.sort((a, b) => a.price - b.price);
        }
    }
}
