import {Component, ElementRef, Input} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { placeholderImgBase64 } from 'src/app/shared/constants/placeholder-img';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';
import { UiService } from 'src/app/shared/services/ui.service';
import { CatalogEntryDTO, CatalogProductDTO } from 'src/app/shared/models/catalog.model';
@Component({
    selector: 'app-catalogue-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
    @Input() catalogItem!: CatalogEntryDTO;

    placeholderImgBase64: string = placeholderImgBase64;
    private subscription: Subscription = new Subscription();

    constructor(
        public readonly uiService: UiService,
        private readonly dialogService: DialogService,
    ) {
    }

    getProductImg(productId?: number, partnerResourcesUuid?: string, small?: boolean): string {
        if (!productId || !partnerResourcesUuid) return '';
        let size = '200x200';
        if (!small) size = '400x400';
        return `${environment.cdnUrl}/partners/resources/${partnerResourcesUuid}/product_${productId}_${size}.jpg`;
    }

    openProductCard(product: CatalogProductDTO, uuid: string, event: MouseEvent) {
        const triggerElement: HTMLElement = new ElementRef(event.currentTarget).nativeElement as unknown as HTMLElement;
        this.subscription = timer(500).subscribe(() => {
            if (!(!!window.ontouchstart || window.innerWidth <= 992 || window.innerHeight <= 500)) {
                this.dialogService.openCatalogCardDialog(product, uuid, triggerElement);
            }
        });
    }

    unsubscribe(): void {
        this.subscription.unsubscribe();
    }
}
