<div class="header bg-white px-3" [class.shadow-none]="!searchService.hideSearchEngine && !isDesktop">
    @if (uiService.logoUrl) {
        <app-img [routerLinkUrl]="marketplaceService.demoMode ? '/demo' : '/'"
                 [src]="uiService.logoUrl"
                 alt="logo"
                 cssClasses="clickable header-logo"
                 imgId="logo" />
    }

    <span class="spacer">
        @if (!searchService.hideSearchEngine) {
            <app-search  class="d-lg-flex d-none justify-content-center w-100" [search_id]="'desktopSearchInput'" />
        }
    </span>

    @if (consumer.email) {
        <span [routerLink]="marketplaceService.demoMode ? '/demo' : '/account'"
              class="clickable text-title bold" data-cy="consumer-email"
              id="consumerEmail">
            {{consumer.email}}
        </span>
    }

    <div [matMenuTriggerFor]="menu" class="menu-btn clickable me-lg-5">
        <div class="menu-btn-burger border-radius-5 clickable" aria-label="menu" aria-hidden="true"></div>
    </div>

</div>
@if (!searchService.hideSearchEngine) {
    <app-search class="py-3 px-2" [search_class]="'d-lg-none d-flex bg-white'" [search_id]="'mobileSearchInput'" [search_icon]="'icons-mobile-wrapper'" />
}

<mat-menu #menu="matMenu" class="mat-menu-panel mt-lg-4 px-3 color-dark-grey">
    <button [routerLink]="marketplaceService.demoMode ? '/demo' : '/'" class="text-large bold"
            mat-menu-item>
        Accueil
    </button>
    <button [disabled]="!consumer.id" class="text-large bold" mat-menu-item routerLink="/account">Mes infos</button>
    <button [disabled]="!consumer.id" class="text-large bold" mat-menu-item routerLink="/account/history">Historique
    </button>
    <button [disabled]="!consumer.id" class="text-large bold" mat-menu-item routerLink="/support">Assistance</button>
    <button class="text-large bold" mat-menu-item (click)="openHowItWorksDialog()">Comment ça marche</button>
    <hr>
    @if (consumer) {
        <button class="text-large bold" mat-menu-item routerLink="/logout">Déconnexion</button>
    }
</mat-menu>
