import { Component, HostBinding, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-catalogue-search',
  templateUrl: './catalogue-search.component.html',
  styleUrl: './catalogue-search.component.scss'
})
export class CatalogueSearchComponent {

  @Output() searchText: EventEmitter<string> = new EventEmitter<string>();

  searchString: string = '';

  @HostBinding('class') classes="d-block bg-white";

  partnerSearch(): void {
    this.searchText.emit(this.searchString);
  }

  cancelSearch(): void {
      this.searchString = '';
      this.partnerSearch();
  }
}
