<div class="dialog-wrapper p-sm-4 px-2 py-3 h-100 d-flex flex-column">
    <div class="search-container mx-auto" [formGroup]="formGroup">
        <div class="input-wrapper d-flex gap-2 align-items-center pb-3">
            <div class="back-icon rounded-circle clickable d-flex d-lg-none" (click)="closeDialog()">
                <i class="fa fa-angle-left"></i>
            </div>
            <input
                placeholder="Rechercher un partenaire, un produit ou ce que vous voulez"
                class="search-input text-larger d-none d-lg-block"
                formControlName="searchString"
            />
            <input
                placeholder="Rechercher un partenaire, un produit ..."
                class="search-input d-block d-lg-none"
                formControlName="searchString"
            />
        </div>
        <div class="icons-wrapper">
            @if (formGroup.controls.searchString.value) {
                <mat-icon class="cancel-icon clickable" (click)="cancelSearch()">cancel</mat-icon>
            }
            <mat-icon class="search-icon clickable rounded-circle" (click)="searchPartners()">search</mat-icon>
        </div>
        @if (!searchService.isSearching) {
            @if (searchService.filteredPartners && searchService.filteredPartners.length > 0) {
                <div class="description my-4">
                    Ces partenaires correspondent à votre recherche :
                </div>
            } @else if (searchService.filteredPartners?.length === 0) {
                <div class="no-match my-4">
                    Désolé, aucun résultat correspondant à votre recherche
                </div>
                <div class="modify-search">
                    Veuillez modifier votre recherche
                </div>
            }
        }
    </div>

    @if (searchService.isSearching) {
        <div class="loader d-flex row">
            <app-loader class="m-auto py-4"/>
        </div>
    } @else {
        <div class="card-content row overflow-auto px-lg-5 gx-2 gx-sm-4">
            @for (partner of searchService.filteredPartners; track partner.id) {
                <app-gift-card
                    (click)="clickGiftCard(partner)"
                    class="clickable col-4 col-sm-3"
                    size="sm"
                    [partner]="partner"/>
            }
        </div>
    }
</div>

<mat-icon class="close-button clickable rounded-circle d-none d-lg-flex" (click)="closeDialog()">
    close
</mat-icon>
