import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {RouterModule} from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {LoaderComponent} from './components/loader/loader.component';
import {GiftCardComponent} from './components/gift-card/gift-card.component';
import {ProductCardComponent} from './components/product-card/product-card.component';
import {GiftCardDialog} from './dialogs/gift-card-dialog/gift-card.dialog';
import {DialogService} from './services/dialog.service';
import {ProductCardDialog} from './dialogs/product-card-dialog/product-card.dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {OrderCardDialog} from './dialogs/order-card-dialog/order-card.dialog';
import {NpsDialog} from './dialogs/nps-dialog/nps.dialog';
import {HowItWorksDialog} from './dialogs/how-it-works-dialog/how-it-works-dialog';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PriceBoxComponent} from './components/price-box/price-box.component';
import {HistoryRewardCardDialog} from './dialogs/history-reward-card-dialog/history-reward-card.dialog';
import {HistoryOrderCardDialog} from './dialogs/history-order-card-dialog/history-order-card.dialog';
import {FooterComponent} from './components/footer/footer.component';
import {MsClientsService, MsConsumersService, MsPartnersService, MsServicesSponsorshipService, MsToolsMarketplaceService, MsUtilsS3Service} from '@isifid/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ImgComponent} from './components/img/img.component';
import {SearchPartnersDialog} from './dialogs/search-partners-dialog/search-partners-dialog';
import {SearchComponent} from './components/search/search.component';
import {NotFoundComponentComponent} from './components/not-found/not-found.component';
import {LayoutNormalComponent} from './components/layouts/layout-normal/layout-normal.component';
import {LayoutNoFooterComponent} from './components/layouts/layout-no-footer/layout-no-footer.component';
import {CatalogCardDialogComponent} from './dialogs/catalog-card-dialog/catalog-card-dialog.component';
@NgModule({
    declarations: [
        HeaderComponent,
        LoaderComponent,
        GiftCardComponent,
        ProductCardComponent,
        PriceBoxComponent,
        GiftCardDialog,
        ProductCardDialog,
        OrderCardDialog,
        NpsDialog,
        SearchPartnersDialog,
        HowItWorksDialog,
        HistoryOrderCardDialog,
        HistoryRewardCardDialog,
        FooterComponent,
        ImgComponent,
        SearchComponent,
        NotFoundComponentComponent,
        LayoutNoFooterComponent,
        LayoutNormalComponent,
        CatalogCardDialogComponent
    ],
    exports: [
        HeaderComponent,
        LoaderComponent,
        GiftCardComponent,
        ProductCardComponent,
        PriceBoxComponent,
        GiftCardDialog,
        ProductCardDialog,
        OrderCardDialog,
        NpsDialog,
        SearchPartnersDialog,
        FooterComponent,
        ImgComponent,
        NgOptimizedImage,
        SearchComponent,
        NotFoundComponentComponent,
        LayoutNoFooterComponent,
        LayoutNormalComponent,
        CatalogCardDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatDialogModule,
        MatExpansionModule,
        MatSnackBarModule,
        ScrollingModule,
        FormsModule,
        MatTabsModule,
        NgOptimizedImage,
        ReactiveFormsModule
    ],
    providers: [
        DialogService,
        MsClientsService,
        MsConsumersService,
        MsPartnersService,
        MsServicesSponsorshipService,
        MsToolsMarketplaceService,
        MsServicesSponsorshipService,
        MsUtilsS3Service
    ]
})
export class SharedModule {
}
