<mat-dialog-content (mouseleave)="closeGiftCardDialog()"
                    (window:resize)="closeGiftCardDialog()"
                    class="gift-card-dialog border-radius-5 m-0 p-0">

    <div class="gift-card-dialog-cover-img position-relative h-100">
        <img [ngSrc]="imgUrl"
             alt="gift-card-img"
             class="object-fit-cover"
             width="363"
             height="457"
             [placeholder]="placeholderImgBase64"
             [priority]="true"
             (error)="uiService.handleMissingImage($event)" />
    </div>
    @if (marketplaceService.isSalesPeriod && partnerIsOffDuringSales) {
        <div class="gift-card-dialog-sales position-absolute top-0 text-large text-center color-white pt-5">
            Indisponible durant les soldes
        </div>
    }

    <div class="gift-card-dialog-header position-absolute top-0 d-flex flex-column justify-content-end">
        <div class="row g-0 mb-2">
            <div class="col-3 ms-3">
                <img (error)="uiService.handleMissingImage($event)"
                     [placeholder]="placeholderImgBase64" [priority]="true"
                     [ngSrc]="env.cdnUrl + '/partners/resources/' + data.partner.resourcesUuid + '/logo_200x200.png'"
                     alt="{{data.partner.name}}" class="bg-white circle" height="70" width="70">
            </div>
            <p class="partner-desc col px-3 text-small color-white">
                <span [innerHTML]="truncateDesc(data.partner.description)"></span>
                @if (data.partner.url && data.partner.url !== '/') {
                    <a [href]="data.partner.url"
                       class="text-decoration-underline text-nowrap"
                       rel="noopener"
                       target="_blank"
                       (click)="trackingService.trackEvent( 'partner Website', 'partner Website ' + data.partner.name, data.partner.name, data.partner.id)">
                        Aller sur le site
                    </a>
                }
            </p>
        </div>
    </div>

    <div class="gift-card-dialog-footer position-absolute bottom-0 color-white pb-4 w-100">

        <div class="row g-0">
            <div class="col-9 mx-auto">
                <button [disabled]="marketplaceService.isSalesPeriod && partnerIsOffDuringSales"
                        (click)="dialogService.openOrderCardDialog(data.partner, data.partnerOffers);
                                 trackingService.trackEvent('partner', 'select partner ' + data.partner.name, 'select-partner ' + data.partner.id)"
                        class="w-100 btn-validate px-5 py-1 text-normal bold" mat-raised-button
                        type="button">
                    Voir l'offre
                </button>
            </div>
        </div>

    </div>

</mat-dialog-content>
