import {Component, Inject, OnDestroy, OnInit, inject} from '@angular/core';
import {Partner, PartnerOffer, Product, TrackingService, Voucher} from '@isifid/core';
import {DialogService} from '../../services/dialog.service';
import {PartnersService} from '../../services/partners.service';
import {UiService} from '../../services/ui.service';
import {environment} from '../../../../environments/environment';
import {ConsumersService} from '../../services/consumers.service';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {BootstrapService} from '../../services/bootstrap.service';
import {MarketplaceService} from '../../services/marketplace.service';
import {switchMap, tap} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {placeholderImgBase64} from "../../constants/placeholder-img";

export interface OrderCardDialogData {
    partner: Partner,
    product: Product,
    partnerOffers: Array<PartnerOffer>,
    goToStep2: boolean,
}

@Component({
    templateUrl: './order-card.dialog.html',
    styleUrls: ['./order-card.dialog.scss']
})
export class OrderCardDialog implements OnInit, OnDestroy {
    env = environment;
    placeholderImgBase64: string = placeholderImgBase64;
    partner: Partner | undefined;
    partnerOffers!: Array<PartnerOffer>;
    productPartnerOffer!: PartnerOffer;
    product: Product | undefined;
    selectedOffer: PartnerOffer | undefined;
    orderedVoucher: Voucher | undefined;
    confirmed = false;
    isOrdering = false;
    smallScreen: boolean = false;
    productBigScreen: boolean = false;
    partnerIsOffDuringSales = false;
    restrictions = [];

    public trackingService = inject(TrackingService);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrderCardDialogData,
        public readonly bootstrapService: BootstrapService,
        public readonly consumersService: ConsumersService,
        public readonly dialogService: DialogService,
        public readonly marketplaceService: MarketplaceService,
        public readonly uiService: UiService,
        private readonly partnersService: PartnersService,
        private readonly location: Location,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        const partnerId = this.setPartnerId();
        if (this.data.goToStep2) {
            if (this.activatedRoute.snapshot.queryParamMap.get('offerId')) {
                this.selectedOffer = this.partnerOffers.find(o => o.id === Number(this.activatedRoute.snapshot.queryParamMap.get('offerId')));
            } else this.selectedOffer = this.partnerOffers[0];
        }
        if (!this.marketplaceService.demoMode) this.partner = this.partnersService.getPartner(partnerId);
        else this.partner = this.data.partner ? this.data.partner : this.partnersService.getPartner(partnerId);

        this.restrictions = this.partner?.restrictions ? JSON.parse(this.partner.restrictions) : [];
        this.partnerIsOffDuringSales = Number(this.partner?.offDuringSales) === 1;
    }

    ngOnDestroy() {
        this.location.go(`${window.location.pathname}`);
    }

    setPartnerId(): number {
        let partnerId;
        if (this.data.product) {
            this.product = this.data.product;
            partnerId = Number(this.product.partnerId);

            this.smallScreen = screen.width <= 768;
            if (!this.smallScreen && this.product) this.productBigScreen = true;

            // Remove maxi from offers list
            // Get the offer with the highest amount
            this.partnerOffers = this.partnersService.getPartnerOffers(partnerId)
                .filter((po) => po.type != 1)
                .sort((a, b) => b.price - a.price);

            const offer = this.partnersService.getOfferForProduct(this.partnerOffers, this.product);

            // Get the first non maxi to match with a fallback
            this.productPartnerOffer = offer ?? this.partnerOffers[0];

            // Save productId and tagId in url params
            const tagId = this.uiService.currentTagSubject.value.id;

            this.location.go(`${window.location.pathname}?tagId=${tagId}&productId=${this.product?.id}`);
        } else {
            partnerId = this.data.partner.id;
            this.partnerOffers = this.data.partnerOffers;
            // Save partnerId and tagId in url params
            const tagId = this.uiService.currentTagSubject.value.id;
            const offerId = this.activatedRoute.snapshot.queryParamMap.get('offerId');
            if (offerId) this.location.go(`${window.location.pathname}?tagId=${tagId}&partnerId=${partnerId}&offerId=${offerId}`);
            else this.location.go(`${window.location.pathname}?tagId=${tagId}&partnerId=${partnerId}`);
        }
        return partnerId;
    }

    selectOfferToOrder(partnerOffer: PartnerOffer) {
        this.selectedOffer = partnerOffer;
        let url = `${window.location.pathname}?tagId=${this.uiService.currentTagSubject.value.id}&offerId=${partnerOffer.id || '-1'}`;

        if (this.data.product) url = url + `&productId=${this.product?.id}`;
        else url = url + `&partnerId=${this.data.partner.id}`;

        this.location.go(url);
    }

    orderSelectedOffer() {
        this.trackingService.trackEvent('order', 'order ' + this.partner?.name, this.partner?.name, this.selectedOffer?.price + ' €').subscribe();
        if (!this.selectedOffer || this.marketplaceService.demoMode) return;

        let orderedVoucher: Voucher;
        this.isOrdering = true;
        this.location.go(window.location.pathname);
        this.consumersService.order(this.selectedOffer)
            .pipe(
                switchMap(order => this.partnersService.getVoucher(order.voucherId).pipe(tap(s => orderedVoucher = s))),

                // Update the consumer entity (rewards + orders)
                switchMap(() => this.consumersService.init(this.consumersService.getConsumer().id)),

                // Need to get all partners entities (partners + offers of partners)
                // as we don't save the full list in the cache
                switchMap(() => this.partnersService.init(this.consumersService.getConsumer().clientId, true)),
                switchMap(() => {
                    // Remove partner and linked entities (products, partner tags etc.)
                    this.bootstrapService.filterEntities();

                    // Refresh current partners & products
                    return this.uiService.selectTag(this.uiService.currentTagSubject.value.id, true);
                })
            )
            .subscribe({
                next: () => {
                    this.selectedOffer = undefined;
                    this.orderedVoucher = orderedVoucher;
                    if (this.consumersService.askForNps) this.dialogService.openNpsDialog();
                },
                error: () => this.uiService.handleError()
            });
    }

    calculateProductComplement(): number {
        const complement = Number(this.product?.price) - this.productPartnerOffer.price;
        return complement <= 0 ? 0 : complement;
    }

    goToEShop() {
        if (!this.partner?.url) return;
        if (this.data.product) window.open(this.product?.url, '_blank');
        else window.open(this.partner.url, '_blank');
    }

    getImageSrc(): string {
        let imgUrl = '';
        if (this.product) imgUrl = this.uiService.getProductImg(this.product.id, this.product.partnerId, this.partner?.resourcesUuid, false);
        else imgUrl = this.env.cdnUrl + '/partners/resources/' + this.partner?.resourcesUuid + '/cover_full_1500x500.jpg';
        return imgUrl;
    }
}
