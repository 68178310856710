import {Injectable} from '@angular/core';
import {ScrollStrategyOptions} from '@angular/cdk/overlay';
import {Order, Partner, PartnerOffer, Product, Reward} from '@isifid/core';
import {GiftCardDialog} from '../dialogs/gift-card-dialog/gift-card.dialog';
import {ProductCardDialog} from '../dialogs/product-card-dialog/product-card.dialog';
import {OrderCardDialog} from '../dialogs/order-card-dialog/order-card.dialog';
import {HistoryOrderCardDialog} from '../dialogs/history-order-card-dialog/history-order-card.dialog';
import {HistoryRewardCardDialog} from '../dialogs/history-reward-card-dialog/history-reward-card.dialog';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NpsDialog} from '../dialogs/nps-dialog/nps.dialog';
import {HowItWorksDialog} from '../dialogs/how-it-works-dialog/how-it-works-dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {SearchPartnersDialog} from '../dialogs/search-partners-dialog/search-partners-dialog';
import {FormGroup} from '@angular/forms';
import { CatalogProductDTO } from '../models/catalog.model';
import { CatalogCardDialogComponent } from '../dialogs/catalog-card-dialog/catalog-card-dialog.component';

@Injectable()
export class DialogService {
    public orderCardDialogRef: MatDialogRef<OrderCardDialog> | undefined;
    public giftCardDialogRef: MatDialogRef<GiftCardDialog> | undefined;
    public productCardDialogRef: MatDialogRef<ProductCardDialog> | undefined;
    public searchPartnersDialogRef: MatDialogRef<SearchPartnersDialog> | undefined;
    public catalogCardDialogRef: MatDialogRef<CatalogCardDialogComponent> | undefined;
    private npsDialogRef: MatDialogRef<NpsDialog> | undefined;
    private howItWorksDialogRef: MatDialogRef<HowItWorksDialog> | undefined;
    private historyOrderCardDialogRef: MatDialogRef<HistoryOrderCardDialog> | undefined;
    private historyRewardCardDialog: MatDialogRef<HistoryRewardCardDialog> | undefined;

    constructor(
        private dialog: MatDialog,
        private readonly sso: ScrollStrategyOptions,
        private breakpointObserver: BreakpointObserver
    ) {}

    public openGiftCardDialog(partner: Partner, partnerOffers: Array<PartnerOffer>, triggerElement: HTMLElement): void {
        this.closeGiftCardDialog();
        const triggerRect = triggerElement.getBoundingClientRect();
        const giftCardWidth = 363;
        const giftCardHeight = 457;

        const positionOffsetX = (giftCardWidth - triggerRect.width) / 2;
        const positionOffsetY = (giftCardHeight - triggerRect.height) / 2;

        let position = {
            top: triggerRect.y - positionOffsetY + 'px',
            left: triggerRect.x - positionOffsetX + 'px'
        };
        let width = giftCardWidth + 'px';
        let height = giftCardHeight + 'px';

        // Small screen width < 768px and height < 500px
        if (window.innerWidth && (window.innerWidth <= 768 || window.innerHeight <= 500)) {
            position = {top: '0', left: '0'};
            width = '';
            height = '';
            // Only open if width of card is fully visible
        } else if (window.innerWidth - triggerRect.x < triggerRect.width) return;
        // Last card position
        else if (window.innerWidth - triggerRect.x < giftCardWidth) position.left = window.innerWidth - (giftCardWidth + 45) + 'px'; // 45 for margin
        // Open at 10px from left if it's first card and left sidenav is hidden
        else if (triggerRect.x < positionOffsetX) position.left = '10px';

        // Open at 10px from top if card is in top of page and the height of card is not fully visible
        if (triggerRect.y < positionOffsetY) position.top = '10px';
        // Open at 10px from bottom if card is in bottom of page and the height of card is not fully visible
        else if (window.innerHeight - triggerRect.bottom < positionOffsetY) {
            position.top = window.innerHeight - (giftCardHeight + 10) + 'px'; // 10 for margin
        }

        this.giftCardDialogRef = this.dialog.open(GiftCardDialog, {
            data: {partner, partnerOffers},
            position,
            height,
            width,
            hasBackdrop: false,
            enterAnimationDuration: '400ms',
            autoFocus: true,
            scrollStrategy: this.sso.noop()
        });
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }

    public openProductCardDialog(product: Product, triggerElement: HTMLElement): void {
        this.closeProductCardDialog();
        const triggerRect = triggerElement.getBoundingClientRect();
        const productCardWidth = 260;
        const productCardHeight = 345;

        const positionOffsetX = (productCardWidth - triggerRect.width) / 2;
        const positionOffsetY = (productCardHeight - triggerRect.height) / 2;
        const position = {
            top: triggerRect.y - positionOffsetY + 'px',
            left: triggerRect.x - positionOffsetX + 'px'
        };
        // Only open if width of card is fully visible
        if (window.innerWidth - triggerRect.x < triggerRect.width) return;
        // Last card position
        else if (window.innerWidth - triggerRect.x < productCardWidth) position.left = window.innerWidth - (productCardWidth + 45) + 'px'; // + 45 for margin
        // Open at 10px from left if it's first card and left sidenav is hidden
        else if (triggerRect.x < positionOffsetX) position.left = '10px';

        // Open at 10px from top if card is in top of page and the height of card is not fully visible
        if (triggerRect.y < positionOffsetY) position.top = '10px';
        // Open at 10px from bottom if card is in bottom of page and the height of card is not fully visible
        else if (window.innerHeight - triggerRect.bottom < positionOffsetY) {
            position.top = window.innerHeight - (productCardHeight + 10) + 'px'; // 10 for margin
        }

        this.productCardDialogRef = this.dialog.open(ProductCardDialog, {
            data: {product},
            position,
            height: productCardHeight + 'px',
            width: productCardWidth + 'px',
            hasBackdrop: false,
            enterAnimationDuration: '400ms',
            autoFocus: false,
            scrollStrategy: this.sso.noop()
        });
    }

    public openOrderCardDialog(partner?: Partner, partnerOffers?: Array<PartnerOffer>, product?: Product, goToStep2?: boolean): void {
        this.closeOrderCardDialog();
        let maxWidth = '1200px';
        let width = '80%';
        let height = '77vh';
        let maxHeight = '850px';

        if (window.innerWidth <= 1200) maxWidth = window.innerWidth + 'px';
        if (window.innerHeight <= 850) maxHeight = window.innerHeight + 'px';

        // Medium screen for partner : 768 < screenWidth < 1500px &&  screenHeight > 644px
        if (window.innerWidth <= 1500 && window.innerWidth > 768 && window.innerHeight > 644) height = '644px';

        // For product only
        if (product) {
            height = '440px';
            maxHeight = '440px';
            width = '1200px';
        }

        // For partner and product
        // Small screen width < 768px
        if (window.innerWidth <= 768 || window.innerHeight <= 644) {
            width = '100%';
            maxWidth = '100%';
            height = '100%';
            maxHeight = '100%';
        }

        this.orderCardDialogRef = this.dialog.open(OrderCardDialog, {
            data: {partner, product, partnerOffers, goToStep2}, height, width, maxWidth, maxHeight, autoFocus: false
        });
    }

    public openCatalogCardDialog(product: CatalogProductDTO, uuid: string, triggerElement: HTMLElement): void {
        this.closeCatalogCardDialog();
        const triggerRect = triggerElement.getBoundingClientRect();
        const productCardWidth = 150;
        const productCardHeight = 215;

        const positionOffsetX = (productCardWidth - triggerRect.width) / 2;
        const positionOffsetY = (productCardHeight - triggerRect.height) / 2;
        const position = {
            top: triggerRect.y - positionOffsetY + 'px',
            left: triggerRect.x - positionOffsetX + 'px'
        };
        // Only open if width of card is fully visible
        if (window.innerWidth - triggerRect.x < triggerRect.width) return;
        // Last card position
        else if (window.innerWidth - triggerRect.x < productCardWidth) position.left = window.innerWidth - (productCardWidth + 45) + 'px'; // + 45 for margin
        // Open at 10px from left if it's first card and left sidenav is hidden
        else if (triggerRect.x < positionOffsetX) position.left = '10px';

        // Open at 10px from top if card is in top of page and the height of card is not fully visible
        if (triggerRect.y < positionOffsetY) position.top = '10px';
        // Open at 10px from bottom if card is in bottom of page and the height of card is not fully visible
        else if (window.innerHeight - triggerRect.bottom < positionOffsetY) {
            position.top = window.innerHeight - (productCardHeight + 10) + 'px'; // 10 for margin
        }

        this.catalogCardDialogRef = this.dialog.open(CatalogCardDialogComponent, {
            data: {product, uuid},
            position,
            height: productCardHeight + 'px',
            width: productCardWidth + 'px',
            hasBackdrop: false,
            enterAnimationDuration: '100ms',
            autoFocus: false,
            scrollStrategy: this.sso.noop()
        });
    }

    public openNpsDialog(): void {
        this.npsDialogRef = this.dialog.open(NpsDialog, {
            hasBackdrop: false,
            panelClass: 'nps-dialog',
            height: '184px',
            width: '300px'
        });
    }

    public openHowItWorksDialog(): void {
        this.howItWorksDialogRef = this.dialog.open(HowItWorksDialog, {
            maxWidth: this.isDesktop ? '800px' : 'calc(100% - 20px)',
            panelClass: 'overflow-auto'
        });
    }

    public openHistoryOrderCardDialog(order: Order, orderAmount: number, secret: string, voucherCode: string, voucherCodeExpiredAt: string): void {
        this.historyOrderCardDialogRef = this.dialog.open(HistoryOrderCardDialog, {
            data: {order, orderAmount, secret, voucherCode, voucherCodeExpiredAt},
            maxWidth: '100%',
            maxHeight: '100vh',
            height: '100vh',
            width: '100%',
            position: {top: '0'},
            hasBackdrop: false
        });
    }

    public openHistoryRewardCardDialog(reward: Reward): void {
        this.historyRewardCardDialog = this.dialog.open(HistoryRewardCardDialog, {
            data: {reward},
            maxWidth: '100%',
            maxHeight: '100vh',
            height: '100vh',
            width: '100%',
            position: {top: '0'},
            hasBackdrop: false
        });
    }

    public openSearchPartnersDialog(formGroup: FormGroup): void {
        this.searchPartnersDialogRef = this.dialog.open(SearchPartnersDialog, {
            data: {
                formGroup: formGroup
            },
            maxWidth: '821px',
            maxHeight: '530px',
            width: '100%',
            panelClass: 'search-partners-dialog'
        });

        this.searchPartnersDialogRef.afterClosed().subscribe(() => {
            this.searchPartnersDialogRef = undefined;
            document.getElementById('mobileSearchInput')?.blur();
        });
    }

    public closeGiftCardDialog(): void {
        this.giftCardDialogRef?.close();
        this.giftCardDialogRef = undefined;
    }

    public closeSearchPartnersDialog(): void {
        this.searchPartnersDialogRef?.close();
    }

    public closeProductCardDialog(): void {
        this.productCardDialogRef?.close();
        this.productCardDialogRef = undefined;
    }

    public closeCatalogCardDialog(): void {
        this.catalogCardDialogRef?.close();
        this.catalogCardDialogRef = undefined;
    }

    public closeOrderCardDialog(): void {
        this.orderCardDialogRef?.close();
    }

    public closeNpsDialog(): void {
        this.npsDialogRef?.close();
    }

    public closeHowItWorksDialog(): void {
        this.howItWorksDialogRef?.close();
    }

    public closeHistoryOrderCardDialog(): void {
        this.historyOrderCardDialogRef?.close();
    }

    public closeHistoryRewardCardDialog(): void {
        this.historyRewardCardDialog?.close();
    }

    public closeAll(): void {
        this.dialog.closeAll();
    }
}
