import {Component, Inject, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject, takeUntil} from 'rxjs';
import {DialogService} from '../../services/dialog.service';
import {Partner, PartnerOffer} from '@isifid/core';
import {PartnersService} from '../../services/partners.service';
import {SearchService} from '../../services/search.service';

export interface SearchPartnersDialogData {
    formGroup: FormGroup
}

@Component({
    templateUrl: './search-partners-dialog.html',
    styleUrl: './search-partners-dialog.scss'
})
export class SearchPartnersDialog implements OnDestroy {
    public formGroup: FormGroup;
    private readonly unsubscribeAll$: Subject<any> = new Subject<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SearchPartnersDialogData,
        public readonly searchService: SearchService,
        private readonly dialogService: DialogService,
        private readonly partnersService: PartnersService
    ) {
        this.formGroup = this.data.formGroup;

        this.formGroup.controls.searchString.valueChanges
            .pipe(takeUntil(this.unsubscribeAll$))
            .subscribe((val: string) => {
                if (this.searchService.searchString !== val) this.searchPartners();
            });
        this.formGroup.controls.searchString.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll$.next(null);
        this.unsubscribeAll$.complete();
    }

    public searchPartners(): void {
        if (this.formGroup.controls.searchString.value?.length > 2) {
            this.searchService.searchPartners(this.formGroup.controls.searchString.value);
        } else {
            this.searchService.cancelSearchPartners();
        }
    }

    public cancelSearch(): void {
        this.formGroup.reset();
    }

    public closeDialog(): void {
        this.dialogService.closeSearchPartnersDialog();
    }

    public clickGiftCard(partner: Partner): void {
        const partnerOffers: Array<PartnerOffer> = this.partnersService.getPartnerOffers(partner.id);
        this.dialogService.openOrderCardDialog(partner, partnerOffers);
    }
}
