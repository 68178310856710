import {Component, ViewEncapsulation} from '@angular/core';
import {UiService} from '../../services/ui.service';
import {ConsumersService} from '../../services/consumers.service';
import {MarketplaceService} from '../../services/marketplace.service';
import {Consumer} from '@isifid/core';
import {DialogService} from '../../services/dialog.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BreakpointObserver} from '@angular/cdk/layout';
import {SearchService} from '../../services/search.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
    consumer: Consumer;
    formGroup: FormGroup;

    constructor(
        public readonly marketplaceService: MarketplaceService,
        public readonly uiService: UiService,
        public readonly searchService: SearchService,
        private readonly consumersService: ConsumersService,
        private readonly dialogService: DialogService,
        private readonly formBuilder: FormBuilder,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        this.formGroup = this.formBuilder.group({
            searchString: ''
        });
        this.consumer = this.consumersService.getConsumer();
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }

    public openHowItWorksDialog(): void {
        this.dialogService.openHowItWorksDialog();
    }

    public openSearchPartnersDialog(event?: KeyboardEvent): void {
        if (!this.dialogService.searchPartnersDialogRef && event?.key !== 'Escape') {
            this.dialogService.openSearchPartnersDialog(this.formGroup);
        }
    }

    public cancelSearch(): void {
        this.formGroup.reset();
    }
}
