export const environment = {
    production: true,
    apiUrl: 'https://api.isifid.com',
    cdnUrl: 'https://cdn.isifid.com',
    matomoSiteId: 22,
    version: '194b854c',
    sentryUrl: 'https://70ff34725c364beb879ed563f620d1bc@o342465.ingest.sentry.io/5874429',
    duration: 5000,
    sponsorshipUrl: 'https://parrainage.isifid.com',
    algoliaApiKey: 'b02ce379716161b25828a504f332d9ff',
    algoliaApplicationId: 'FMH4KXVDE3'
};
