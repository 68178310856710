import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-layout-no-footer',
  templateUrl: './layout-no-footer.component.html',
  styleUrl: './layout-no-footer.component.scss'
})
export class LayoutNoFooterComponent {
  @HostBinding('class') classes = 'container-fluid d-flex flex-column px-0 m-0 py-toolbar-height max-vw-100 h-100'
}
