<mat-dialog-content (mouseleave)="dialogService.closeCatalogCardDialog()"
                    (window:resize)="dialogService.closeCatalogCardDialog()"
                    class="product-card-dialog d-flex flex-column justify-content-end border-radius-5 m-0 p-0">
    <img alt="product-card-img"
         class="product-card-img"
         [priority]="true"
         width="150"
         height="150"
         [ngSrc]="getImgUrl"
         [placeholder]="placeholderImgBase64"
         (error)="uiService.handleMissingImage($event)"/>

    <div class="dialog-content">
        <div class="dialog-text-description d-flex justify-content-center align-items-center">
            <p class="px-1 dialog-text">{{ data.product.label?.toLowerCase() }}</p>
        </div>
        <div class="dialog-text-price d-flex justify-content-center align-items-center">
            <p class="px-1 mb-1 dialog-text">{{ data.product.price }} €</p>
        </div>
    </div>
</mat-dialog-content>
