<link rel="stylesheet" href="history-reward-card.dialog.scss">
<div class="history-reward-card px-3">
    <div (click)="closeHistoryRewardCardDialog()" class="row my-3">
        <div class="col-1 me-3">
            <img alt="arrow left" height="" src="assets/icons/arrow-left.svg" width="">
        </div>
        <div class="col text-title bold">
            Récompense
        </div>
    </div>

    <div class="reward-card rounded my-4 p-3">
        <div class="row text-center">
            <app-img [src]="uiService.logoUrl" alt="logo" cssClasses="w-100" />
        </div>

        <div class="row px-2">
            <div class="col px-4 color-dark-grey">
                <hr>
            </div>
        </div>

        <div class="row my-2">
            <div class="col color-dark-grey">Montant</div>
            <div class="col bold text-end">{{data.reward.amount}} €</div>
        </div>
        <div class="row my-2">
            <div class="col color-dark-grey">Offre</div>
            <div class="col bold text-end">{{data.reward.label}}</div>
        </div>
        <div class="row my-2">
            <div class="col color-dark-grey">Reçue le</div>
            <div class="col bold text-end">{{data.reward.createdAt | date: 'dd/MM/yyyy'}}</div>
        </div>
        <div class="row my-2">
            <div class="col color-dark-grey">Valable jusqu'au</div>
            <div class="col bold text-end">{{data.reward.expiresAt | date: 'dd/MM/yyyy'}}</div>
        </div>
    </div>

    <div class="row mt-5 mb-3">
        <div class="col">
            <button (click)="closeHistoryRewardCardDialog()"
                    class="btn-validate w-100 py-4 text-normal"
                    mat-raised-button routerLink="/support">
                Un problème avec ma récompense ?
            </button>
        </div>
    </div>

</div>
