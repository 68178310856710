import { Component, HostBinding, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Consumer} from '@isifid/core';
import {ConsumersService} from '../../services/consumers.service';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
    consumer: Consumer;
    formGroup: FormGroup;

    @Input() public search_class: string = '';
    @Input() public search_id: string = '';
    @Input() public search_icon: string = '';

    @HostBinding('class') classes="d-block bg-white";

    constructor(
        private readonly consumersService: ConsumersService,
        private readonly dialogService: DialogService,
        private readonly formBuilder: FormBuilder,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        this.formGroup = this.formBuilder.group({
            searchString: ''
        });
        this.consumer = this.consumersService.getConsumer();
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }

    public openSearchPartnersDialog(event?: KeyboardEvent): void {
        if (!this.dialogService.searchPartnersDialogRef && event?.key !== 'Escape') {
            this.dialogService.openSearchPartnersDialog(this.formGroup);
        }
    }

    public cancelSearch(): void {
        this.formGroup.reset();
    }
}
