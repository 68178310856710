<div id="products" class="d-flex position-relative align-items-center gap-2 overflow-visible product">
    @for (product of catalogItem.partnerProducts; track product.id; let index = $index) {
        <div data-cy="partner-product" class="partner-product" (mouseleave)="unsubscribe()" (mouseenter)="openProductCard(product, catalogItem.partnerResourcesUuid, $event)">
            <div class="img-text">
                <div class="position-relative img-container">
                    <img [ngSrc]="getProductImg(product?.id, catalogItem.partnerResourcesUuid, true)"
                            [placeholder]="placeholderImgBase64"
                            (error)="uiService.handleMissingImage($event)"
                            class="img-size"
                            [fill]="true"
                            [alt]="product.label">
                </div>
                <div class="bg-white product-text">
                    <p class="text-center w-100 text-truncate label-text">{{product.label}}</p>
                    <p class="text-center price-text">{{ product.price | number: '1.2-2' }} &euro;</p>
                </div>
            </div>
        </div>
        }
</div>
