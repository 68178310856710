<div class="history-order-card px-3">
    <div (click)="closeHistoryOrderDialog()" class="row my-3">
        <div class="col-1 me-3">
            <img alt="arrow left" height="" src="assets/icons/arrow-left.svg" width="">
        </div>
        <div class="col text-title bold">
            Cartes cadeaux
        </div>
    </div>

    <div class="order-card rounded my-4 p-3">
        <div class="d-flex justify-content-center">
            <img [src]="uiService.getPartnerLogoImg(partner)"
                 alt="{{partner?.name}} logo"
                 class="circle my-3"
                 height="96" id="partner-logo" width="96">
        </div>

        <div class="row px-2">
            <div class="col px-4 color-dark-grey">
                <hr>
            </div>
        </div>

        <div class="row my-2">
            <div class="col color-dark-grey">Valeur</div>
            <div class="col bold text-end color-dark-grey">{{data.orderAmount}} €</div>
        </div>
        <div class="row my-2">
            <div class="col color-dark-grey">Code</div>
            <div class="col bold text-end color-dark-grey">{{data.voucherCode}}</div>
        </div>
        @if (data.secret) {
            <div class="row my-2">
                <div class="col color-dark-grey">Pin</div>
                <div class="col bold text-end color-dark-grey">{{data.secret}}</div>
            </div>
        }
        <div class="row my-2">
            <div class="col color-dark-grey">Commandé le</div>
            <div class="col bold text-end color-dark-grey">{{data.order.createdAt | date: 'dd/MM/yyyy'}}</div>
        </div>
        <div class="row my-2">
            <div class="col color-dark-grey">Valable jusqu'au</div>
            <div class="col bold text-end color-dark-grey">{{data.voucherCodeExpiredAt}}</div>
        </div>
    </div>

    <div class="row mt-5 mb-3">
        <div class="col">
            <button (click)="uiService.copyToClipboard(data.voucherCode, $event.target)"
                    class="btn-validate w-100 py-4 text-normal"
                    mat-raised-button>
                Copier le code
            </button>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <button (click)="gotoSite(partner?.url)"
                    class="btn-validate w-100 py-4 text-normal"
                    mat-raised-button>
                Aller sur le site
            </button>
        </div>
    </div>

    <div class="row my-3">
        <div class="col">
            <button (click)="sendOrderEmail($event.target, data.order.id)"
                    class="btn-validate w-100 py-4 text-normal"
                    mat-raised-button>
                Recevoir le code par email
            </button>
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
            <button (click)="closeHistoryOrderDialog()"
                    class="btn-validate w-100 py-4 text-normal"
                    mat-raised-button routerLink="/support">
                Un problème avec mon code ?
            </button>
        </div>
    </div>

</div>
