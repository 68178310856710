import {Injectable} from '@angular/core';
import {AuthenticationService} from '@isifid/core';
import {BehaviorSubject, forkJoin, skip} from 'rxjs';
import {PartnersService} from './partners.service';
import {ConsumersService} from './consumers.service';
import {UiService} from './ui.service';
import {MarketplaceService} from './marketplace.service';

@Injectable({providedIn: 'root'})
export class BootstrapService {
    public isInitialised: BehaviorSubject<boolean>;
    private isInitInProgress: boolean;
    private BALANCE_THRESHOLD_FOR_DEDICATED_TAG = 20;

    constructor(
        private readonly marketplaceService: MarketplaceService,
        private readonly partnersService: PartnersService,
        private readonly authenticationService: AuthenticationService,
        private readonly consumersService: ConsumersService,
        private readonly uiService: UiService
    ) {
        // We have tokens so the app should be able to init itself
        this.isInitialised = new BehaviorSubject<boolean>(false);
        this.isInitInProgress = false;


        // Init the app if we have an ongoing auth or we're in demo mode
        if (this.marketplaceService.demoMode) this.initDemo();
        else if (this.authenticationService.isAuth.value) {
            this.initEntitiesFromCache();
            this.init();
        }
        // We skip the first value as it's emitted when we init the app
        // We deal with the first value above
        this.authenticationService.isAuth.pipe(skip(1)).subscribe((isAuth) => {
            // Init the app if is auth
            if (isAuth && !this.isInitialised.value) this.init();
            else if (!isAuth) {
                // Init demo doesn't require to be auth so we can init the demo if demo mode is active, eg a page refresh
                if (this.marketplaceService.demoMode) this.initDemo();
                // Destroy the init if it was initialized but auth has been destroyed, except for demo
                else if (this.isInitialised.value) this.destroy();
            }
        });
    }

    public destroyDemo() {
        this.marketplaceService.destroyDemo();
        this.destroy();
    }

    public filterEntities(): void {
        const rewards = new Map<number, number>;
        // Group active rewards by reward type id and sum all their amount
        this.consumersService.rewards
            .filter((r) => r.status === 'active')
            .forEach(r => {
                if (!rewards.has(r.rewardTypeId)) rewards.set(r.rewardTypeId, r.balance);
                else rewards.set(r.rewardTypeId, rewards.get(r.rewardTypeId) + r.balance);
            });


        // Complete rewards amounts
        // We add all amount from higher reward type id
        // e.g. premium amount is premium amount + platinium amount
        // For now the max reward type id is 3
        let amount = 0;
        for (let i = 3; i > 0; i--) {
            amount += rewards.get(i) ?? 0;
            if (amount > 0) rewards.set(i, amount);
        }

        // Filter partners and their offers
        this.partnersService.filter(rewards, this.consumersService.orders, this.marketplaceService.demoMode);

        // Filter all tags
        this.marketplaceService.filterTags(this.partnersService.getPartnersList());

        // When the balance is small but some partners can still be ordered
        // we create a new tag regrouping all of them
        if (this.partnersService.getPartnersList().length &&
            this.consumersService.getConsumer().balance < this.BALANCE_THRESHOLD_FOR_DEDICATED_TAG) {
            this.marketplaceService.addLowBudgetTag(this.partnersService.getPartnersList(), this.BALANCE_THRESHOLD_FOR_DEDICATED_TAG);
        }
    }

    private init() {
        if (this.isInitInProgress) return;
        else this.isInitInProgress = true;

        let consumerId: any;
        let clientId: any;
        if (!this.marketplaceService.demoMode) {
            const token = this.authenticationService.getRefreshToken();
            if (token) {
                const userDataFromToken = JSON.parse(window.atob(token.split('.')[1]));
                consumerId = userDataFromToken.consumerId;
                clientId = userDataFromToken.clientId;
            } else {
                this.authenticationService.logout(true, false);
            }
        } else {
            consumerId = '-1';
            clientId = this.marketplaceService.settings?.clientId;
        }

        // Logout if we don't have enough info
        if (!clientId || !consumerId) return this.authenticationService.logout(true, false);

        this.marketplaceService.initPrimaryEntities(clientId).subscribe({
            next: () => {
                forkJoin([
                    this.marketplaceService.initSecondaryEntities(),
                    // Don't refresh if demo mode is on
                    this.partnersService.init(clientId, !this.marketplaceService.demoMode),
                    this.consumersService.init(consumerId),
                    this.uiService.init()
                ]).subscribe({
                    next: () => {
                        this.filterEntities();
                        this.isInitialised.next(true);
                        this.isInitInProgress = false;
                    }, error: (error) => {
                        console.error('initSecondaryEntities error: ' + error);
                        this.authenticationService.logout(true, false);
                        this.isInitInProgress = false;
                    }
                });
            }, error: (error) => {
                console.error('initPrimaryEntities error: ' + error);
                this.authenticationService.logout(true, false);
                this.isInitInProgress = false;
            }
        });
    }

    private destroy() {
        this.isInitialised?.next(false);
        this.isInitInProgress = false;
        this.marketplaceService.destroy();
        this.partnersService.destroy();
        this.consumersService.destroy();
        this.uiService.destroy();
    }

    private initEntitiesFromCache() {
        this.marketplaceService.initEntitiesFromCache();
        this.partnersService.initEntitiesFromCache();
        this.uiService.initEntitiesFromCache();
    }

    private initDemo() {
        this.marketplaceService.getSettingsByDomain()
            .subscribe((settings) => {
                forkJoin([
                    this.partnersService.initDemo(settings.clientId),
                    this.consumersService.initDemo()
                ]).subscribe({
                    next: () => this.init(),
                    error: (error) => {
                        console.error('initDemo error: ' + error);
                        this.authenticationService.logout(true, false);
                    }
                });
            });
    }
}

