import {Component, Inject} from '@angular/core';
import {LabelledReward} from '@isifid/core';
import {UiService} from '../../services/ui.service';
import {DialogService} from '../../services/dialog.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface HistoryRewardCardDialogData {
    reward: LabelledReward,
}

@Component({
    templateUrl: './history-reward-card.dialog.html',
    styleUrls: ['./history-reward-card.dialog.scss']
})
export class HistoryRewardCardDialog {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HistoryRewardCardDialogData,
        public readonly uiService: UiService,
        public readonly dialogService: DialogService
    ) {
    }

    closeHistoryRewardCardDialog(): void {
        this.dialogService.closeHistoryRewardCardDialog();
    }
}
