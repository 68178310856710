import {Component, Input} from '@angular/core';
import {Product} from '@isifid/core';
import {UiService} from '../../services/ui.service';
import { placeholderImgBase64 } from '../../constants/placeholder-img';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {
    placeholderImgBase64: string = placeholderImgBase64;

    @Input() public product: Product | undefined;

    constructor(
        public readonly uiService: UiService
    ) {
    }
}
