import { Component, Input } from '@angular/core';
import { Partner, TrackingService } from '@isifid/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, of, map } from 'rxjs';
import { CatalogEntryDTO } from 'src/app/shared/models/catalog.model';
import { environment } from 'src/environments/environment';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-catalogue-item',
  templateUrl: './catalogue-item.component.html',
  styleUrl: './catalogue-item.component.scss'
})
export class CatalogueItemComponent {

  @Input() catalogItem!: CatalogEntryDTO;

  env = environment;
  isProduct: boolean = false;
  isDesktop$: Observable<boolean> = of(true);

  constructor(
    public readonly uiService: UiService,
    private readonly trackingService: TrackingService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    this.isDesktop$ = this.breakpointObserver.observe('(min-width: 992px)').pipe(map(s => s.matches));
  }

  formatPartner(catalog: CatalogEntryDTO): Partner {
    const partner = new Partner();
    partner.name = catalog.partnerName;
    partner.resourcesUuid = catalog.partnerResourcesUuid;
    return partner;
  }

  activeProduct(): void {
    this.isProduct = !this.isProduct;
  }

  goToPartnerUrl(catalogItem: CatalogEntryDTO) {
    if (catalogItem?.partnerUrl) window.open(catalogItem.partnerUrl, '_blank');
    this.trackingService
        .trackEvent('partner Website', 'partner Website ' + catalogItem?.partnerName, catalogItem?.partnerName, catalogItem?.partnerId?.toString())
        .subscribe();
  }
}
