import {Injectable, inject} from '@angular/core';
import {
    CacheService,
    Client, ConfigurationService,
    MarketplaceSettings,
    MarketplaceSlide,
    MsClientsService,
    MsServicesSponsorshipService,
    MsToolsMarketplaceService,
    Partner,
    PartnerTag,
    Product,
    ProductTag,
    SponsorshipSettings,
    Tag
} from '@isifid/core';
import {BehaviorSubject, finalize, forkJoin, Observable, of, tap} from 'rxjs';
import {PartnersService} from './partners.service';
import {SearchService} from './search.service';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class MarketplaceService {
    public tags!: BehaviorSubject<Array<Tag>>;
    public settings!: MarketplaceSettings;
    public client!: Client;
    public demoMode = false;
    public demoAmount: number | undefined;
    public demoRewardTypeId: number | undefined;
    public sponsorshipSettings!: SponsorshipSettings | undefined;
    public slides!: Array<MarketplaceSlide>;
    public isSalesPeriod = false;
    private partnersTags!: Array<PartnerTag>;
    private productsTags!: Array<ProductTag>;
    // Not in cache
    private allTags!: Array<Tag>;

    private msClientsService = inject(MsClientsService);
    private msServicesSponsorshipService = inject(MsServicesSponsorshipService);
    private partnersService = inject(PartnersService);
    private cacheService = inject(CacheService);
    private router = inject(Router);
    private configurationService = inject(ConfigurationService);
    private searchService = inject(SearchService);
    private msToolsMarketplaceService = inject(MsToolsMarketplaceService);

    constructor() {
        const pathname = window.location.pathname.replace('//', '/');
        if (pathname.startsWith('/demo')) {
            this.setDemoMode();
        } else if (
            !pathname.startsWith('/log/r32/') &&
            !pathname.startsWith('/auth') &&
            !pathname.startsWith('/email') &&
            this.cacheService.getContent('demo_mode')
        ) {
            this.router.navigate(['/demo']).then(() => window.location.reload());
        }
    }

    destroy() {
        this.settings = new MarketplaceSettings();
        this.client = new Client();
        this.slides = [];
        this.partnersTags = [];
        this.productsTags = [];
        this.allTags = [];
        this.tags.next([]);
        this.demoMode = false;
        this.demoAmount = undefined;
        this.demoRewardTypeId = undefined;
    }

    destroyDemo() {
        this.cacheService.clearAllCache();
        this.destroy();
    }

    initPrimaryEntities(clientId: string): Observable<any> {
        return new Observable((o) => {
            forkJoin([
                this.initSettings(clientId),
                this.initAllTags(),
                this.initClient(clientId),
                this.initSponsorship()
            ]).subscribe({
                next: () => {
                    this.setTags();
                    if (!this.demoMode) this.cacheService.addPermanentContent('tags', this.tags.value);
                    o.next();
                    o.complete();
                }, error: (error) => o.error(error)
            });
        });
    }

    initSecondaryEntities(): Observable<any> {
        return forkJoin([
            this.initSlides(),
            this.initPartnersTags(),
            this.initProductsTags(),
            this.initSalesPeriod()
        ]);
    }

    initEntitiesFromCache() {
        this.client = this.cacheService.getContent('client');
        if (this.client) this.searchService.hideSearchEngine = this.configurationService.getValueByKey(this.client.configuration, 'hideSearchEngine');
        this.sponsorshipSettings = this.cacheService.getContent('sponsorship_settings');
        this.partnersTags = this.cacheService.getContent('partners_tags');
        this.productsTags = this.cacheService.getContent('products_tags');
        this.tags = new BehaviorSubject<Array<Tag>>(this.cacheService.getContent('tags'));
    }

    public getSettingsByDomain(): Observable<MarketplaceSettings> {
        if (this.settings?.id) {
            return new Observable<any>((o) => {
                o.next(this.settings);
                o.complete();
            });
        }

        const demoParams = this.demoMode ? {demo: true} : {};

        return new Observable((o) => {
            (
                this.demoMode ?
                    this.msToolsMarketplaceService.getSettingsByDomain([], demoParams) :
                    this.msToolsMarketplaceService.getSettingsByDomain()
            ).subscribe({
                next: settings => {
                    this.settings = settings;
                    o.next(this.settings);
                    o.complete();
                },
                error: (error) => o.error(error)
            });
        });
    }

    public getCatalogByClientId(clientId: number): Observable<any> {
        return this.msToolsMarketplaceService.getCatalogByClientId(clientId);
    }

    public sendEmailSupport(data: any): Observable<any> {
        return this.msToolsMarketplaceService.sendEmailSupport(data);
    }

    public getPartnersByTagId(tagId: number): Array<Partner> {
        const partners: Array<Partner> = [];
        this.getPartnersTagsByTagId(tagId).forEach(partnerTag => {
            const partner = this.partnersService.getPartner(partnerTag.partnerId);
            if (partner) partners.push(partner);
        });
        return partners;
    }

    public getPartnersTagsByTagId(tagId: number): Array<PartnerTag> {
        const partnersTags = this.partnersTags.filter((partnerTag: PartnerTag) => partnerTag.tagId === tagId);

        // Sort partners by rank
        partnersTags.sort((a: any, b: any) => a.rank - b.rank);
        return partnersTags;
    }

    public getProductsTagsByTagId(tagId: number): Array<ProductTag> {
        const productsTags = this.productsTags.filter((productTag: ProductTag) => productTag.tagId === tagId);
        // Sort by rank
        productsTags.sort((a: any, b: any) => a.rank - b.rank);
        return productsTags;
    }

    public getProductsTagsByProductId(productId: number): Array<ProductTag> {
        const productsTags = this.productsTags.filter((productTag: ProductTag) => productTag.productId === productId);
        // Sort by rank
        productsTags.sort((a: any, b: any) => a.rank - b.rank);
        return productsTags;
    }

    public filterTags(partners: Array<Partner>): void {
        // We don't filter tags if we don't have partners so we can display all tags
        if (partners.length === 0) return;

        // Only keep used partners tags
        this.partnersTags = this.partnersTags.filter((partnerTag: PartnerTag) => {
            return partners.find((partner: Partner) => partnerTag.partnerId === partner.id);
        });

        // Only keep used tags
        this.tags.next(
            this.tags.value.filter((tag: Tag) => this.partnersTags.find((partnerTag: PartnerTag) => partnerTag.tagId === tag.id))
        );

        if (!this.demoMode) {
            // Save new values for tags
            this.cacheService.addPermanentContent('partners_tags', this.partnersTags);
            this.cacheService.addPermanentContent('tags', this.tags.value);
        }
    }

    public filterProducts(products: Product[], selectedTag: Tag): Product[] {
        let strictProductsDisplay = false;
        if (selectedTag.configuration) strictProductsDisplay = JSON.parse(selectedTag.configuration)?.strictProductsDisplay;

        // The selected tag configuration says we only display products with a product tag equals to the selected tag
        if (strictProductsDisplay) {
            const productsTags = this.getProductsTagsByTagId(selectedTag.id);
            return products.filter(product => {
                const productTag = productsTags.find(productTag => productTag.tagId === selectedTag.id && productTag.productId === product.id);
                return !!productTag;
            });
        } else {
            // Otherwise, we don't display any partner products
            // If it has a product tag corresponding to a "strictProductsDisplay" tag configuration
            return products.filter(product => {
                if (!product.id) return false;

                const productTags = this.getProductsTagsByProductId(product.id);
                if (!productTags) return true;

                for (const productTag of productTags) {
                    const tag = this.getTags().find((t) => t.id === productTag.tagId);
                    if (tag?.configuration) {
                        const strictProductsDisplay = JSON.parse(tag.configuration).strictProductsDisplay;
                        if (strictProductsDisplay) return false;
                    }
                }
                return true;
            });
        }
    }

    public addLowBudgetTag(partners: Array<Partner>, amount: number): void {
        const tags = this.tags.value;

        // Create a new tag
        const tag: Tag = new Tag();
        tag.id = 0;
        tag.name = `Moins de ${amount}€`;
        // Use the configuration of the first tag
        tag.configuration = tags[0].configuration;

        // Add this tag to the first position
        tags.unshift(tag);
        this.tags.next(tags);
        this.cacheService.addPermanentContent('tags', this.tags.value);

        partners.forEach((partner) => {
            const partnerTag: PartnerTag = new PartnerTag();
            partnerTag.tagId = tag.id;
            partnerTag.partnerId = partner.id;
            this.partnersTags.push(partnerTag);
        });

        this.cacheService.addPermanentContent('partners_tags', this.partnersTags);
    }

    public getSettings(): MarketplaceSettings {
        return this.settings;
    }

    public getClient(): Client {
        return this.client;
    }

    public getTags(): Array<Tag> {
        return this.tags.value;
    }

    private initSettings(clientId: string): Observable<any> {
        if (this.settings?.clientId > 0) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        return new Observable((o) => {
            this.msToolsMarketplaceService.getSettingsByClientId(clientId).subscribe({
                next: (settings) => {
                    this.settings = settings;
                    o.next();
                    o.complete();
                }, error: (error) => o.error(error)
            });
        });
    }

    private initAllTags(): Observable<any> {
        if (this.allTags?.length) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        return new Observable((o) => {
            this.msToolsMarketplaceService.getAllTags()
                .pipe(finalize(() => o.complete()))
                .subscribe({
                    next: allTags => {
                        this.allTags = allTags;
                        o.next();
                    }, error: (error) => o.error(error)
                });
        });
    }

    private initClient(clientId: string): Observable<any> {
        if (this.client?.id) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        // In demo mode, we need a fake client
        if (this.demoMode) {
            return new Observable<any>((o) => {
                this.client = new Client();
                o.next();
                o.complete();
            });
        }

        return new Observable((o) => {
            this.msClientsService.getClient(clientId)
                .pipe(finalize(() => o.complete()))
                .subscribe({
                    next: client => {
                        this.client = client;
                        this.searchService.hideSearchEngine = this.configurationService.getValueByKey(this.client.configuration, 'hideSearchEngine');
                        this.cacheService.addPermanentContent('client', client);
                        o.next();
                    }, error: (error) => o.error(error)
                });
        });
    }

    private initSlides(): Observable<any> {
        if (this.slides?.length) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        const slidesObs: Array<Observable<MarketplaceSlide>> = [];
        this.getSettings().slides.forEach((slideId: number) => {
            slidesObs.push(this.msToolsMarketplaceService.getSlide(slideId));
        });

        return new Observable((o) => {
            // If no slides are present, this is an error
            if (!slidesObs.length) return o.error();
            forkJoin(slidesObs)
                .pipe(finalize(() => o.complete()))
                .subscribe({
                    next: (slides) => {
                        this.slides = [];
                        slides.forEach(slide => {
                            // Remove sponsorship slide if sponsorship online not enabled
                            if (slide.linkUrl === '[sponsorship-online-link]' && !this.sponsorshipSettings?.online) return;
                            this.slides.push(slide);
                        });
                        if (!this.demoMode) this.cacheService.addPermanentContent('slides', this.slides);
                        o.next();
                    }, error: (error) => o.error(error)
                });
        });
    }

    private initSponsorship(): Observable<any> {
        if (this.sponsorshipSettings) {
            return new Observable<any>((o) => {
                o.next();
                o.complete();
            });
        }

        return new Observable((o) => {
            if (this.demoMode) {
                o.next();
                o.complete();
            }

            // If no sponsorship settings are present, we continue
            this.msServicesSponsorshipService.getSettingsByClientId()
                .pipe(finalize(() => {
                    this.cacheService.addPermanentContent('sponsorship_settings', this.sponsorshipSettings);
                    o.next();
                    o.complete();
                }))
                .subscribe({
                    next: (settings) => this.sponsorshipSettings = settings,
                    error: () => this.sponsorshipSettings = undefined
                });
        });
    }

    private initPartnersTags(): Observable<any> {
        this.partnersTags = [];
        if (this.getTags().length === 0) return of({});

        return this.msToolsMarketplaceService.getPartnerTagsByTagsId(this.getTags().map(s => s.id))
            .pipe(
                tap((partnersTags: PartnerTag[]) => {
                    this.partnersTags = partnersTags;
                    if (!this.demoMode) this.cacheService.addPermanentContent('partners_tags', this.partnersTags);
                })
            );
    }

    private initProductsTags(): Observable<any> {
        this.productsTags = [];
        if (this.getTags().length === 0 || (this.productsTags?.length && !this.demoMode)) return of({});

        return this.msToolsMarketplaceService.getProductTagsByTagsId(this.getTags().map(s => s.id))
            .pipe(
                tap((productsTags: ProductTag[]) => {
                    this.productsTags = productsTags;
                    if (!this.demoMode) this.cacheService.addPermanentContent('products_tags', this.productsTags);
                })
            );
    }

    private initSalesPeriod(): Observable<any> {
        return new Observable((o) => {
            if (!this.settings.configuration) {
                o.next();
                o.complete();
            }

            const configuration = JSON.parse(this.settings.configuration);
            const salesStartAt = new Date(configuration.salesStartAt);
            const salesEndAt = new Date(configuration.salesEndAt);
            const now = new Date();
            if (now > salesStartAt && now < salesEndAt) this.isSalesPeriod = true;
            o.next();
            o.complete();
        });
    }

    private setDemoMode(): void {
        this.cacheService.clearAllCache();
        this.demoMode = true;
        this.cacheService.addPermanentContent('demo_mode', true);
        const urlParams = new URLSearchParams(window.location.search);
        const amount = Number(urlParams.get('amount'));
        const rewardTypeId = Number(urlParams.get('rewardTypeId'));
        if (amount) this.demoAmount = amount;
        if (rewardTypeId) this.demoRewardTypeId = rewardTypeId;
    }

    private setTags(): void {
        const tags: Tag[] = [];
        this.settings.partnerTags.forEach(tagId => {
            const tag = this.allTags.find((t: Tag) => t.id === tagId);
            if (tag) tags.push(tag);
        });
        tags.sort((a, b) => a.type.localeCompare(b.type));

        this.tags = new BehaviorSubject<Array<Tag>>(tags);
    }
}

