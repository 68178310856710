import {Component, Inject, OnInit} from '@angular/core';
import {Partner, PartnerOffer, TrackingService} from '@isifid/core';
import {UiService} from '../../services/ui.service';
import {environment} from '../../../../environments/environment';
import {DialogService} from '../../services/dialog.service';
import {MarketplaceService} from '../../services/marketplace.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SafeHtml} from '@angular/platform-browser';
import {placeholderImgBase64} from '../../constants/placeholder-img';

export interface GiftCardDialogData {
    partner: Partner,
    partnerOffers: Array<PartnerOffer>
}

@Component({
    templateUrl: './gift-card.dialog.html',
    styleUrls: ['./gift-card.dialog.scss']
})
export class GiftCardDialog implements OnInit {
    private charLimit = 89;
    env = environment;
    placeholderImgBase64: string = placeholderImgBase64;
    partnerIsOffDuringSales = false;
    imgUrl: string = `${environment.cdnUrl}/marketplace/img/image-not-available.png`;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: GiftCardDialogData,
        public readonly uiService: UiService,
        public readonly dialogService: DialogService,
        public readonly marketplaceService: MarketplaceService,
        public readonly trackingService: TrackingService
    ) {
        if (data.partner && Number(data.partner.offDuringSales) === 1) this.partnerIsOffDuringSales = true;
    }

    ngOnInit() {
        if (this.data.partner.resourcesUuid) this.imgUrl = `${this.env.cdnUrl}/partners/resources/${this.data.partner.resourcesUuid}/cover_card_300x400.jpg`;
    }

    closeGiftCardDialog(): void {
        this.dialogService.closeGiftCardDialog();
    }

    truncateDesc(description: string): SafeHtml {
        if (!description) return '';
        if (description.length <= this.charLimit) return description + `&nbsp;&nbsp`;

        let truncateDescription = description.substring(0, this.charLimit) + '...';
        const countBr = truncateDescription.split('<br>').length - 1;
        // If <br> reduce charLimit
        if (countBr) {
            this.charLimit -= 41;
            truncateDescription = truncateDescription.substring(0, this.charLimit) + '...';
        }
        return truncateDescription + `&nbsp;&nbsp`;
    }
}

