import {Component, Inject} from '@angular/core';
import {Order, Partner} from '@isifid/core';
import {UiService} from '../../services/ui.service';
import {DialogService} from '../../services/dialog.service';
import {PartnersService} from '../../services/partners.service';
import {ConsumersService} from '../../services/consumers.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface HistoryOrderCardDialogData {
    order: Order,
    orderAmount: number,
    secret?: string,
    voucherCode: string,
    voucherCodeExpiredAt: any
}

@Component({
    templateUrl: './history-order-card.dialog.html',
    styleUrls: ['./history-order-card.dialog.scss']
})
export class HistoryOrderCardDialog {
    partner?: Partner;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HistoryOrderCardDialogData,
        private readonly consumersService: ConsumersService,
        public readonly dialogService: DialogService,
        private readonly partnersService: PartnersService,
        public readonly uiService: UiService
    ) {
        this.partnersService.getPartnerFromApi(data.order.partnerId)
            .subscribe({next: (partner) => this.partner = partner, error: () => this.uiService.handleError()});
    }

    sendOrderEmail(element: any, orderId: number) {
        element.textContent = 'Email envoyé';
        element.disabled = true;
        this.consumersService.sendOrderEmail(orderId).subscribe({error: () => this.uiService.handleError()});
    }

    closeHistoryOrderDialog(): void {
        this.dialogService.closeHistoryOrderCardDialog();
    }

    public gotoSite(url?: string): void {
        if (url) window.open(url, '_blank');
    }
}
